import * as React from 'react'
import * as Quagga from 'quagga'

import ImageCropper from '../ImageCropper/ImageCropper'
import Button from '../Button/Button'
import Loader from '../Loader/Loader'
import Icon from '../Icon/Icon'
import InfoMessage from '../InfoMessage/InfoMessage'
import TipsOverlay from '../BarcodeScanner/TipsOverlay/TipsOverlay'

import './style.scss'

const QUAGGA_OPTIONS = {
  inputStream: {
    size: 1024,
    singleChannel: false
  },
  locator: {
    patchSize: 'medium',
    halfSample: true,
  },
  numOfWorkers: navigator.hardwareConcurrency || 4,
  decoder: {
    readers: ['code_128_reader', 'ean_reader']
  },
  locate: true,
  src: null,
}

interface IProps {
  onDetect: Function,
  onFailDetect: Function,
  onDetectName: Function,
  failDetectStatus: any,
}

interface IState {
  decoding: boolean,
  cannotDecode: boolean,
  cannotOcr: boolean,
  performingOcr: boolean,
  cropping: any,
  rotating: boolean,
  isTipsVisible: boolean
}

class BarcodeScannerStatic extends React.Component<IProps, IState> {

  private _cropArea

  constructor(props) {
    super(props)

    this.state = {
      decoding: false,
      cannotDecode: false,
      cannotOcr: false,
      performingOcr: false,
      cropping: null,
      rotating: false,
      isTipsVisible: false
    }

    this.onDetect = this.onDetect.bind(this)
    this.onImageUpload = this.onImageUpload.bind(this)
    this.toggleTipsOverlay = this.toggleTipsOverlay.bind(this)

    this._cropArea = {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.failDetectStatus && !this.props.failDetectStatus) {
      this.reset()
    } else if (!prevProps.failDetectStatus && this.props.failDetectStatus) {
      this.setState({
        decoding: false,
        cannotDecode: true,
      })
    }
  }

  componentWillMount() {
    if (this.props.failDetectStatus && !this.state.cannotDecode) {
      this.setState({
        decoding: false,
        cannotDecode: true,
      })
      this.props.onFailDetect()
    }
  }

  onDetect(result) {
    if (result) {
      this.setState({
        decoding: false,
      })

      this.props.onDetect(result)
    } else {
      this.setState({
        decoding: false,
        cannotDecode: true,
      })
      this.props.onFailDetect()
    }
  }

  onImageUpload(event) {
    const src = URL.createObjectURL(event.target.files[0])

    this.setState({
      cropping: src,
    })
  }

  cropAndDecodeBarcode() {
    this.decode(this.state.cropping, this._cropArea.x, this._cropArea.y, this._cropArea.width,
      this._cropArea.height)

    this.setState({
      cropping: null
    })
  }

  parseProductName(x) {
    return x.replace(/\n/g, ' ')
      .replace(/ +/g, ' ')
      .replace(/[^A-Za-z -/"'0-9]/g, '')
      .trim()
  }

  async rotateCropImage(dir) {
    this.setState({
      rotating: true,
    })

    const newSrc = await this.rotateImage(this.state.cropping, dir)

    this.setState({
      cropping: newSrc,
      rotating: false,
    })
  }

  rotateImage(src, dir = 'right') {
    return new Promise(resolve => {
      const img = new Image()

      img.addEventListener('load', () => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const rotDegrees = dir === 'right' ? 90 : -90

        canvas.setAttribute('width', img.height.toString())
        canvas.setAttribute('height', img.width.toString())
        context.translate(canvas.width / 2, canvas.height / 2)
        context.rotate((rotDegrees * Math.PI) / 180)
        context.drawImage(img, -img.width / 2, -img.height / 2)

        resolve(canvas.toDataURL('image/jpeg'))
      })

      img.src = src
    })
  }

  createCanvasFromImage(src, x, y, width, height, canvasWidth, canvasHeight) {
    canvasWidth = canvasWidth || width
    canvasHeight = canvasHeight || height

    return new Promise(resolve => {
      const img = new Image(src)

      img.addEventListener('load', () => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')

        canvas.setAttribute('width', canvasWidth)
        canvas.setAttribute('height', canvasHeight)
        context.drawImage(img, x, y, width, height, 0, 0, canvasWidth, canvasHeight)

        resolve(canvas)
      })

      img.src = src
    })
  }

  setCropArea(area) {
    this._cropArea = area
  }

  /**
   * Decodes the image uploaded by the user
   */
  decode(src, x, y, width, height) {
    this.setState({
      decoding: true,
    })

    const img = new Image()
    img.addEventListener('load', async () => {
      let code = await this.decodeSlice(img, x, y, width, height)

      if (!code) {
        // Try to crop to the center and see if we can find the code there
        code = await this.decodeFromCenter(img, 0.8)
        code = code || await this.decodeFromCenter(img, 0.6)
      }

      if (!code) {
        // If nothing else worked, we slice up the image in multiple pieces and try to find the
        // code from some more zoomed in images
        code = await this.decodeFromSlices(img)
      }

      this.onDetect(code)
    })

    if (navigator.userAgent.match(/iPhone/i)) {
      this.rotateImage(src).then((src: string) => {
        img.src = src
      })
    } else {
      img.src = src
    }
  }

  getScaledSize(width, height, maxEdge) {
    let ratio = 1
    if (width >= height && width > maxEdge) {
      ratio = maxEdge / width
    } else if (height >= width && height > maxEdge) {
      ratio = maxEdge / height
    }

    return [width * ratio, height * ratio].map(x => Math.floor(x))
  }

  getTopLeftBoxPoint(box) {
    const newBox = [...box]
    newBox.sort((a, b) => (a[0] < b[0] ? -1 : 1))

    if (newBox[0][1] < newBox[1][1]) {
      return newBox[0]
    }

    return newBox[1]
  }

  getBottomRightBoxPoint(box) {
    const newBox = [...box]
    newBox.sort((a, b) => (a[0] > b[0] ? -1 : 1))

    if (newBox[0][1] > newBox[1][1]) {
      return newBox[0]
    }

    return newBox[1]
  }

  /**
   * Tries to get the barcode by cropping only the center of the image
   */
  async decodeFromCenter(img, cropFactor) {
    const centerWidth = Math.floor(img.width * cropFactor)
    const centerHeight = Math.floor(img.height * cropFactor)
    const centerX = (img.width - centerWidth) / 2
    const centerY = (img.height - centerHeight) / 2

    return this.decodeSlice(img, centerX, centerY, centerWidth, centerHeight)
  }

  decodeFromSlices(img) {
    return new Promise(async resolve => {
      const columnsToSlice = 3
      const rowsToSlice = 4
      const sliceHeight = img.height / (rowsToSlice - 1)
      const sliceStepHeight = img.height / rowsToSlice
      const sliceWidth = img.width / (columnsToSlice - 1)
      const sliceStepWidth = img.width / columnsToSlice

      let result
      let row = 0

      while (!result && row < rowsToSlice) {
        let column = 0
        while (!result && column < columnsToSlice) {
          result = await this.decodeSlice(
            img,
            sliceStepWidth * column,
            sliceStepHeight * row,
            sliceWidth,
            sliceHeight
          )
          column++
        }
        row++
      }

      resolve(result)
    })
  }

  decodeSlice(img, x, y, width, height) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    if (x + width > img.width) {
      width = img.width - x
    }

    if (y + height > img.height) {
      height = img.height - y
    }

    const [scaledWidth, scaledHeight] = this.getScaledSize(width, height, 800)

    canvas.setAttribute('width', scaledWidth.toString())
    canvas.setAttribute('height', scaledHeight.toString())
    context.drawImage(img, x, y, width, height, 0, 0, scaledWidth, scaledHeight)

    return new Promise(resolve => {
      Quagga.decodeSingle({
        ...QUAGGA_OPTIONS,
        src: canvas.toDataURL('image/jpeg'),
      }, result => {
        if (result && result.codeResult && result.codeResult.code) {
          resolve(parseInt(result.codeResult.code, 10))
        } else {
          resolve(null)
        }
      })
    })
  }

  reset() {
    this.setState({
      cannotDecode: false,
      cannotOcr: false,
      performingOcr: false,
    }, () => {
      setTimeout(() => {
        this.refs.cameraLabel.click()
      }, 200)
    })
  }

  renderUploadButton(hidden) {
    return (
      <div style={hidden ? { display: 'none' } : {}}>
        <label ref="cameraLabel" htmlFor="barcode-scanner-static__input" className="barcode-scanner-static__label">
          <img className="barcode-scanner-static__label-img"
            src="/images/scan-image1.jpg" alt="Barcode Scanner" />
          <span className="barcode-scanner-static__label-icon">
            <Icon name="camera" />
          </span>
        </label>
        <input type="file" accept="image/*" onChange={this.onImageUpload}
          id="barcode-scanner-static__input" className="barcode-scanner-static__input" />

        <div className="barcode-scanner-static__options">
          <div className="barcode-scanner-static__tips">
            Upload or take a photo of a barcode
          </div>
        </div>
      </div>
    )
  }

  renderCannotDecodeMessage() {
    return (
      <div className="barcode-scanner-static__cannot-decode">
        <InfoMessage message="Sorry! Unable to scan barcode." type="error" align="center" />
        {this.getScannerTips('Scan Again', () => this.reset())}
      </div>
    )
  }

  renderCannotOcrMessage() {
    return (
      <div className="barcode-scanner-static__cannot-decode">
        We couldn't read the product's name
        <div className="barcode-scanner-static__cannot-decode-tip">
          Tip: make sure the name is focused when taking the photo
        </div>
        <Button handleClick={() => this.reset()} label="Scan again" />
      </div>
    )
  }

  getScannerTips(buttonCopy, onButtonClick) {
    return (
      <div className="barcode-scanner-static__tips-scan">
        <div className="barcode-scanner-static__tips-images">
          <div className="barcode-scanner-static__tips-images-tip1">
            <img src="/images/scanner-tip1.jpg" alt="Tip" />
            <span className="skip-icon">
              <Icon name="close" />
            </span>
          </div>
          <div className="barcode-scanner-static__tips-images-tip2">
            <img src="/images/scanner-tip2.jpg" alt="Tip" />
            <span className="add-icon">
              <Icon name="checkmark" color="#fff" />
            </span>
          </div>
        </div>
        <div className="barcode-scanner-static__tips-list-header">
          <p>A Few Troubleshooting Tips:</p>
        </div>
        <ul className="barcode-scanner-static__tips-list">
          <li>Flatten the barcode.</li>
          <li>Focus your shot by moving your phone closer to or further from the barcode.</li>
          <li>Fit the scan-space box to the barcode.</li>
          <li>Make sure your shot is well lit.</li>
        </ul>
        <Button handleClick={onButtonClick} label={buttonCopy} />
      </div>
    )
  }

  renderTipsOverlay() {
    return (
      <div className="barcode-scanner-static__tips-overlay">
        <span onClick={this.toggleTipsOverlay} className="barcode-scanner-static__tips-overlay-close">
          <i className="icon-close" />
        </span>
        <p className="barcode-scanner-static__tips-overlay-title">Helpful Scanning Tips</p>
        {this.getScannerTips('got it', this.toggleTipsOverlay)}
      </div>
    )
  }

  renderCropOverlay() {
    const src = this.state.cropping
    let height = window.innerHeight - 52
    if (window.innerWidth === 320) {
      height = window.innerHeight - 100
    }
    const holderHeight = { height: `${height}px` }

    return (
      <div className="barcode-scanner-static__crop-overlay" style={holderHeight}>
        <div className="barcode-scanner-static__crop-overlay-content">
          <ImageCropper src={src} onAreaChange={x => this.setCropArea(x)}
            maxHeight={`${height}px`} />

          <div className="barcode-scanner-static__crop-overlay-buttons">
            <label htmlFor="barcode-scanner-static__input"
              className="barcode-scanner-static__crop-overlay-button barcode-scanner-static__crop-overlay-button--retake">
              <div className="icon">
                <Icon name="camera" />
              </div>
              <span>Retake</span>
            </label>
            <div onClick={() => this.rotateCropImage('right')}
              className="barcode-scanner-static__crop-overlay-button barcode-scanner-static__crop-overlay-button--rotate">
              <div className="icon">
                <Icon name="refresh" color="#fff" />
              </div>
              <span>Rotate</span>
            </div>
            <div onClick={this.toggleTipsOverlay}
              className="barcode-scanner-static__crop-overlay-button barcode-scanner-static__crop-overlay-button--help">
              <div className="icon">
                <Icon name="help" color="#fff" />
              </div>
              <span>Help</span>
            </div>
          </div>

          <div className="barcode-scanner-static__crop-overlay-crop-button">
            <div className="barcode-scanner-static__tip">Crop your photo so that the barcode is front and center.</div>
            <Button
              label="Crop Photo"
              handleClick={() => this.cropAndDecodeBarcode()} />
          </div>
        </div>
      </div>
    )
  }

  toggleTipsOverlay() {
    this.setState({
      isTipsVisible: !this.state.isTipsVisible
    })
  }

  getUploadScreen() {
    return (
      <div className="barcode-scanner-static__upload">
        <div className="barcode-scanner-static__upload-inner">
          <i className="icon icon-file-upload" />
          <p className="barcode-scanner-static__upload-inner-copy">Uploading Your Photo</p>
        </div>
      </div>
    )
  }

  render() {
    const { decoding, cannotDecode, cannotOcr, cropping, performingOcr, isTipsVisible } = this.state

    return (
      <div className="barcode-scanner-static">
        {isTipsVisible && <TipsOverlay onCloseClick={this.toggleTipsOverlay} onButtonClick={this.toggleTipsOverlay} buttonCopy={'got it'} />}
        {!decoding && !cannotDecode && !cannotOcr && !performingOcr
          && this.renderUploadButton(cropping)}
        {decoding && this.getUploadScreen()}
        {performingOcr && <Loader />}
        {cannotDecode && this.renderCannotDecodeMessage()}
        {cannotOcr && this.renderCannotOcrMessage()}
        {cropping && this.renderCropOverlay()}
      </div>
    )
  }
}

export default BarcodeScannerStatic
