import * as React from 'react'
import { createComponent } from 'react-fela'

export default createComponent((props) => ({
  fontFamily: 'Roboto-Light',
  fontSize: '30px',
  fontWeight: 100,
  paddingTop: '10px',
  marginBottom: '20px',
}), 'h1')
