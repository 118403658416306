import gql from 'graphql-tag'
import DefectCategoryFragment from './DefectCategoryFragment.graphql'

export default gql`
  query DefectCategoriesQuery {
    defectCategories {
      ...DefectCategoryFragment
    }
  }
  ${DefectCategoryFragment}
`
