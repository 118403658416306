import * as React from 'react'
import 'webrtc-adapter/out/adapter'
import * as Quagga from 'quagga'
import { throttle, cloneDeep } from 'lodash'
import Icon from '../Icon/Icon'
import TipsOverlay from './TipsOverlay/TipsOverlay'

import './style.scss'

const QUAGGA_OPTIONS = {
  inputStream: {
    type: 'LiveStream',
    target: '.barcode-scanner',
    constraints: {
      width: {
        min: 800,
        ideal: 1280,
      },
      height: {
        min: 600,
        ideal: 1280,
      },
      aspectRatio: { min: 1, max: 2 },
    },
    area: {
      top: '20%',
      right: '20%',
      left: '20%',
      bottom: '20%',
    },
  },
  locator: {
    patchSize: 'small',
    halfSample: false,
  },
  numOfWorkers: navigator.hardwareConcurrency || 4,
  frequency: 6,
  decoder: {
    readers: ['code_128_reader', 'ean_reader'],
  },
  locate: true,
}

interface IProps {
  onDetect: Function,
  minDetections?: any
}

interface IState {
  width: number,
  height: number,
  cameras: any[],
  tipsOverlayVisible: boolean,
  detected: boolean
}

class BarcodeScanner extends React.Component<IProps, IState> {

  private lastDetection
  private usedCamera: number

  static defaultProps = {
    minDetections: 3
  }

  constructor(props) {
    super(props)

    this.state = {
      width: 0,
      height: 0,
      cameras: [],
      tipsOverlayVisible: false,
      detected: false,
    }

    this.onDetect = this.onDetect.bind(this)

    this.lastDetection = {
      code: null,
      detected: 0,
    }

    this.usedCamera = 0

    this.calculateDimensions = throttle(this.calculateDimensions.bind(this), 300, { leading: true })
    this.toggleTipsOverlay = this.toggleTipsOverlay.bind(this)
  }

  async componentDidMount() {
    await this.getCameras()

    const config = cloneDeep(QUAGGA_OPTIONS)
    const camera = this.getPrefferedCamera()
    config.inputStream.constraints.deviceId = camera
    this.initQuagga(config)

    this.usedCamera = this.state.cameras.findIndex(x => x.deviceId === camera)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateDimensions)
    window.removeEventListener('orientationchange', this.calculateDimensions)
    this.stopQuagga()
  }

  getPrefferedCamera() {
    const cameraId = localStorage.getItem('barcode-scanner.preferred-camera')
    if (!cameraId) {
      // The user hasn't stored a preferred camera, let's try to find a back facing one
      let camera = this.state.cameras.find(x => /back|environment/.test(x.label))
      if (this.state.cameras.length > 1 && !camera) {
        camera = this.state.cameras[1]
      }
      return camera ? camera.deviceId : this.state.cameras[0].deviceId
    }

    return cameraId
  }

  initQuagga(config) {
    Quagga.init(config, err => {
      if (err) {
        console.error('Cannot init quagga: ', err)
      }

      Quagga.start()
      this.calculateDimensions()
      window.addEventListener('resize', this.calculateDimensions)
      window.addEventListener('orientationchange', this.calculateDimensions)
    })

    Quagga.onDetected(this.onDetect)
  }

  stopQuagga() {
    Quagga.offDetected(this.onDetect)
    Quagga.stop()
  }

  getCameras() {
    return new Promise(async resolve => {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const cameras = devices.filter(x => x.kind === 'videoinput')
      this.setState({
        cameras,
      }, () => {
        resolve()
      })
    })
  }

  switchCamera() {
    let newCameraIndex = this.usedCamera + 1

    if (newCameraIndex >= this.state.cameras.length) {
      newCameraIndex = 0
    }

    const deviceId = this.state.cameras[newCameraIndex].deviceId

    const config = cloneDeep(QUAGGA_OPTIONS)
    config.inputStream.constraints.deviceId = deviceId

    this.stopQuagga()
    this.initQuagga(config)

    this.usedCamera = newCameraIndex
    localStorage.setItem('barcode-scanner.preferred-camera', deviceId)
  }

  calculateDimensions() {
    this.setState({
      width: Quagga.canvas.dom.overlay.offsetWidth,
      height: Quagga.canvas.dom.overlay.offsetHeight,
    })
  }

  onDetect(result) {
    if (result.codeResult && result.codeResult.code !== this.lastDetection.code) {
      this.lastDetection.code = result.codeResult.code
      this.lastDetection.detected = 1
    } else if (result.codeResult) {
      this.lastDetection.detected++
    }

    if (this.lastDetection.detected >= this.props.minDetections) {
      this.setState({
        detected: true
      }, () => {
        setTimeout(() => {
          this.props.onDetect(parseInt(result.codeResult.code, 10))
        }, 1000)
      })
    }
  }

  toggleTipsOverlay() {
    this.setState({
      tipsOverlayVisible: !this.state.tipsOverlayVisible
    })
  }

  render() {
    const { width, height, cameras, tipsOverlayVisible, detected } = this.state

    let holderHeight

    const containerStyle = {
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    }

    if (window.innerWidth === 320) {
      holderHeight = { height: `calc(${window.innerHeight}px - 100px)` }
    } else {
      holderHeight = { height: `calc(${window.innerHeight}px - 114px)` }
    }

    const visibility = document.querySelector('.drawingBuffer') ? {} : { display: 'none' }

    return (
      <div className="barcode-scanner__holder" style={holderHeight}>
        <div className="barcode-scanner__container" style={containerStyle}>
          <div className="barcode-scanner" style={visibility} />
          <div className="barcode-scanner__position-hinter">
            <div className={`barcode-scanner__position-hinter-rectangle ${detected ? 'barcode-scanner__position-hinter-rectangle--success' : ''}`}>
              {!detected &&
                <div>
                  <em></em>
                  <span className="barscan"></span>
                </div>
              }
              {detected &&
                <div className="barcode-scanner__position-hinter-rectangle__message">
                  <span className="add-icon">
                    <Icon name="checkmark" color="#fff" />
                  </span>
                  <p>Barcode Scanned Successfully!</p>
                </div>}
              <div className="barcode-scanner__holder__tip">Be sure to fit the barcode within the box.</div>
            </div>
          </div>
        </div>
        {tipsOverlayVisible && <TipsOverlay onButtonClick={this.toggleTipsOverlay} onCloseClick={this.toggleTipsOverlay} buttonCopy={'got it'} />}
        <div onClick={this.toggleTipsOverlay} className="barcode-scanner__holder__scanning-tip">
          <div className="icon">
            <Icon name="help" color="#fff" />
          </div>
          <span className="barcode-scanner__holder__scanning-tip-text">
            See More Scanning Tips
          </span>
        </div>

        {cameras.length > 1 &&
          <div className="barcode-scanner__switch-camera" onClick={this.switchCamera}>
            <div className="icon">
              <Icon name="refresh" color="#fff" />
            </div>
          </div>
        }
      </div>
    )
  }
}

export default BarcodeScanner
