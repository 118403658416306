const theme = {
  primaryColor: '#70b5e1',
  secondaryColor: '#53b678',
  errorColor: `rgba(255, 56, 35, .6)`,
  gridGutter: '5%',
  gridColumns: 12,
  bodyGutter: '30px',
}

export enum EBreakpoints {
  MOBILE = 'mobile',
  TABLET = 'tablet',
}

export const initTheme = (renderer) => {
  renderer.renderFont('Roboto', ['fonts/Roboto-Regular.ttf'], {})
  renderer.renderFont('Roboto', ['fonts/Roboto-Bold.ttf'], {
    fontWeight: 'bold'
  })
  renderer.renderFont('Roboto-Light', ['fonts/Roboto-Light.ttf'], {
    fontWeight: 100,
  })
  renderer.renderStatic(`
    * {
      margin: 0;
      padding: 0;
      font-family: 'Roboto';
      box-sizing: border-box;
      outline: none;
    }

    img {
      max-height: 100%;
      display: block;
    }

    body, html {
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: rgba(0, 0, 0, .7);
    }

    a {
      color: rgba(0, 0, 0, .7);
    }
  `);
}

export default theme
