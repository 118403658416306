import { createStore, combineReducers, applyMiddleware } from 'redux'
import { routerMiddleware, routerReducer } from 'react-router-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import history from './history'
import test from './reducers/test'

const historyMiddleware = routerMiddleware(history)
const store = createStore(
  combineReducers({
    test,
    router: routerReducer,
  }),
  applyMiddleware(historyMiddleware),
  composeWithDevTools()
)

export default store
