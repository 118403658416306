import * as React from 'react'
import { createComponentWithProxy, createComponent } from 'react-fela'

interface IProps {
  name: string
  value: string
  handleChange: Function
  label?: string
  error?: string
  className?: string
}

const DateField = (props: IProps) => (
  <label className={props.className}>
    {props.label}
    <input
      name={props.name}
      type="date"
      placeholder="mm/dd/yyyy"
      value={props.value}
      onChange={(e) => props.handleChange(e)} />
      {props.error &&
        <Error>{props.error}</Error>
      }
  </label>
)

const Error = createComponent((props) => ({
  fontSize: '11px',
  color: props.theme.errorColor,
}))

export default createComponentWithProxy(() => ({
  '> input': {
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, .5)',
    marginLeft: '10px',
  }
}), DateField)

