import gql from 'graphql-tag'

export default gql`
  fragment DefectCategoryFragment on DefectCategory {
    id
    label
    options {
      id
      label
      options {
        id
        label
      }
    }
  }
`


