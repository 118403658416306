import * as React from 'react'
import * as R from 'ramda'
import { EBreakpoints } from '../client/styles/themes/default'

interface ILayerData {
  id: string
  label: string
  options: ILayerData[]
}

export const getLayerData = (data: ILayerData[], layers?: string[]): ILayerData[] => {
  const rootData = {
    options: data
  }
  if (!layers) { return data }
  const layersWithValues = layers.filter(R.identity)
  if (!layersWithValues.length) { return data }
  const parent = layersWithValues.reduce((acc, layerId) => {
    return acc.options.find((category) => category.id === layerId)
  }, rootData)
  return parent.options
    ? parent.options
    : []
}

export const getColumnSizes = (cols: React.ReactChild[], total: number) => {
  return React.Children.map(cols, (col: any) => ({
    [EBreakpoints.MOBILE]: col.props[EBreakpoints.MOBILE] || total,
    [EBreakpoints.TABLET]: col.props[EBreakpoints.TABLET] || total,
  }))
}

export const isDate = (date: string): boolean => {
  const segments = date.split('/')
  if (segments.length < 3) {
    return false
  } else {
    return true
  }
}

export const boolToConfirmation = (flag: boolean): string => flag ? 'YES' : 'NO'

export const rotateImage = (img, angle = 90) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  canvas.setAttribute('width', img.height.toString())
  canvas.setAttribute('height', img.width.toString())
  context.translate(canvas.width / 2, canvas.height / 2)
  context.rotate((angle * Math.PI) / 180)
  context.drawImage(img, -img.width / 2, -img.height / 2)
  return canvas.toDataURL('image/jpeg')
}

/**
 * Similar to formik setFieldValue, but updates the value in the component state
 * @param setState
 */
export const setFieldToState = (setState) =>
  (name: string, value: any) => setState({
    [name]: value
  })
