import * as React from "react";
import * as scrollToComponent from "react-scroll-to-component";
import { Formik } from "formik";
import { graphql, compose } from "react-apollo";
import { createComponent } from "react-fela";
import { getLayerData, setFieldToState } from "../../../universal/utils";
import { Link, Route, Prompt } from "react-router-dom";
import fetch from "unfetch";
import Wrapper from "../../components/Grid/Wrapper";
import Row from "../../components/Grid/Row";
import Col from "../../components/Grid/Col";
import Expand from "../../components/Grid/Expand";
import Contract from "../../components/Grid/Contract";
import Section from "../../components/Section/Section";
import Heading from "../../components/Heading/Heading";
import TextField from "../../components/TextField/TextField";
import Pick from "../../components/Pick/Pick";
import Tick from "../../components/Tick/Tick";
import Choice from "../../components/Pick/Choice";
import RadioChoice from "../../components/Pick/RadioChoice";
import LayerPick from "../../components/LayerPick/LayerPick";
import SubHeading from "../../components/SubHeading/SubHeading";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Note from "../../components/Note/Note";
import ScrollLocation from "../../components/ScrollLocation/ScrollLocation";
import Attachment from "../../components/Attachment/Attachment";
import BarcodeScanner from "../../components/BarcodeScanner/BarcodeScanner";
import BarcodeScannerStatic from "../../components/BarcodeScannerStatic/BarcodeScannerStatic";
import FullOverlay from "../../components/FullOverlay/FullOverlay";

import DefectCategoriesQuery from "../../graphql/DefectCategoriesQuery.graphql";
import AddIssueMutation from "../../graphql/AddIssueMutation.graphql";
import { NotifyTeamMutation } from "../../graphql/NotifyTeamMutation.graphql";
import { RemoveAttachmentMutation } from "../../graphql/RemoveAttachmentMutation.grapgql";

enum EIssueType {
  DEFECT = "defect",
  DESIGN = "design",
  ASSEMBLY = "assembly"
}
enum EImpact {
  CALM = "calm",
  ANNOYED = "annoyed",
  DISAPPOINTED = "disappointed",
  INFURIATED = "infuriated"
}
enum EOccupationalArea {
  STORES = "stores",
  DC_REPAIR_SHOP = "dc-repair-shop",
  INDUSTRY_CITY = "industry-city",
  INSTALLATION_SERVICES = "installation-services",
  CORPORATE = "corporate"
}

interface IProps {
  data: {
    loading: boolean;
    defectCategories: any;
  };
  addIssueAction: any;
  removeAttachment: any;
  notifyTeam: Function;
  history: any;
}

interface IState {
  isValidSku: boolean;
  isOther: boolean;
  isUploading: number;
  isSaving: boolean;
  isSaveErr: string;
  isLiveStream: boolean;
}

interface IFormValues {
  name: string;
  associateNumber: string;
  occupationalArea: EOccupationalArea;
  storeNumber: string;
  issueType: EIssueType;
  sku: string;
  skuDescription: string;
  purchaseOrderNo: string;
  defectClassification: Array<any>;
  other: string;
  description: string;
  RepeatIssue: boolean;
  Return: boolean;
  impact: EImpact;
  attachments: Array<any>;
  suggestion: string;
}

class Issue extends React.Component<IProps, IState> {
  scrollRefs: any;
  setFieldToState: Function;
  initialFormValues: IFormValues;

  constructor(props) {
    super(props);
    this.state = {
      isValidSku: true,
      isOther: false,
      isUploading: 0,
      isSaving: false,
      isSaveErr: "",
      isLiveStream: !!navigator.getUserMedia
    };
    this.initialFormValues = {
      name: "",
      associateNumber: "",
      occupationalArea: null,
      storeNumber: "",
      issueType: null,
      sku: "",
      skuDescription: "",
      purchaseOrderNo: "",
      defectClassification: [],
      other: "",
      description: "",
      RepeatIssue: false,
      Return: false,
      impact: null,
      attachments: [],
      suggestion: ""
    };
    this.setFieldToState = setFieldToState(this.setState.bind(this));
  }

  addRef(key, fieldName) {
    if (!key || !this[key]) {
      this[key] = {};
    }
    return ref => (this[key][fieldName] = ref);
  }

  scrollToInvalid(errors) {
    const scrollOrder = [
      "name",
      "associateNumber",
      "occupationalArea",
      "issueType",
      "sku",
      "skuDescription",
      "defectClassification",
      "other",
      "description",
      "impact"
    ];
    const currentField = scrollOrder.find(field => errors[field]);
    if (currentField) {
      scrollToComponent(this.scrollRefs[currentField], {
        align: "top"
      });
    }
  }

  validate(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "First and last name is required";
    }
    if (!values.associateNumber) {
      errors.associateNumber = "Associate number is required";
    }
    if (!values.occupationalArea) {
      errors.occupationalArea = "Occupational area is required";
    }
    if (
      values.occupationalArea === EOccupationalArea.STORES &&
      values.storeNumber === ""
    ) {
      errors.storeNumber = "Store number is required";
    }
    if (!values.issueType) {
      errors.issueType = "Please select issue type";
    }
    if (!values.sku) {
      errors.sku = "SKU is required";
    }
    if (!values.skuDescription) {
      errors.skuDescription = "SKU description is required";
    }
    if (!values.description) {
      errors.description = "Please describe the issue";
    }

    if (values.issueType === EIssueType.DEFECT) {
      if (this.state.isOther) {
        if (!values.other) {
          errors.other = "Other category is required";
        }
      } else {
        if (values.defectClassification.length < 1) {
          errors.defectClassification = "Please select at least one category";
        }
      }
      if (!values.impact) {
        errors.impact = "Please select customer impact";
      }
    }

    return errors;
  }

  save(issue) {
    this.setState({ isSaving: true });
    const issueWithSkuValidation = {
      ...issue,
      validSku: this.state.isValidSku
    };
    this.props
      .addIssueAction(issueWithSkuValidation)
      .then(() => {
        this.props.history.push("/", {
          message: "Thank you for helping us keep an eye on our products."
        });
      })
      .catch(err => {
        this.setState({
          isSaveErr: JSON.stringify(err),
          isSaving: true
        });
      });
  }

  notify(issue, error) {
    this.props.notifyTeam(issue, error).then(() =>
      this.props.history.push("/", {
        message:
          "Details about the error were sent to our team. Thank you for helping us keep an eye on our products."
      })
    );
  }

  /**
   * Api returns an empty object when SKU not found
   */
  async fetchSkuDescription(sku: number): Promise<string | undefined> {
    try {
      const apiURL = "https://beta.westelm.com";
      console.log("API URL is: " + apiURL);
      const rawData = await fetch(`${apiURL}/api/skus/${sku}`);
      const data = await rawData.json();
      return data.name;
    } catch (err) {
      console.log(err);
    }
  }

  setSkuValue(
    setFieldValue: Function,
    setFieldError: Function,
    setFieldTouched: Function
  ): Function {
    return async barcode => {
      setFieldValue("sku", barcode);

      this.props.history.push("/issue");

      try {
        const skuDescription = await this.fetchSkuDescription(barcode);
        if (!skuDescription) {
          this.setState({ isValidSku: false });
          setFieldValue("skuDescription", ""); //Aditya: Added to clear the SKU description
        } else {
          this.setState({ isValidSku: true });
          setFieldError("sku", "");
          setFieldValue("skuDescription", skuDescription);
        }
      } catch (err) {
        console.error(err);
      }
      setFieldTouched("sku", true);
    };
  }

  render() {
    if (this.props.data.loading) {
      return <div>Loading</div>;
    }

    return (
      <Wrapper>
        <IssueBar>
          <Link to="/">
            <Icon name="back" />
          </Link>
          <IssueTitle>New Product Quality Report</IssueTitle>
        </IssueBar>
        <Formik
          initialValues={this.initialFormValues}
          validate={this.validate.bind(this)}
          onSubmit={this.save.bind(this)}
          render={({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldError,
            setFieldTouched
          }) => (
            <form onSubmit={handleSubmit}>
              <Section background="#e3e0d7">
                <Heading>Your details</Heading>
                <Row>
                  <Col tablet="6">
                    <ScrollLocation
                      innerRef={this.addRef("scrollRefs", "name")}
                    />
                    <TextField
                      expand
                      name="name"
                      label="First and last name *"
                      value={values.name}
                      error={touched.name && errors.name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col tablet="6">
                    <ScrollLocation
                      innerRef={this.addRef("scrollRefs", "associateNumber")}
                    />
                    <TextField
                      expand
                      name="associateNumber"
                      label="Associate number *"
                      value={values.associateNumber}
                      error={touched.associateNumber && errors.associateNumber}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ScrollLocation
                      innerRef={this.addRef("scrollRefs", "occupationalArea")}
                    />
                    <SubHeading
                      error={
                        touched.occupationalArea && errors.occupationalArea
                      }
                    >
                      Occupational area *
                    </SubHeading>
                    <Pick
                      name="occupationalArea"
                      value={values.occupationalArea}
                      handleChange={setFieldValue}
                    >
                      <Choice
                        value={EOccupationalArea.STORES}
                        label="Stores"
                        icon="store"
                      />
                      <Choice
                        value={EOccupationalArea.DC_REPAIR_SHOP}
                        label="DC Repair Shop"
                        icon="wrench"
                      />
                      <Choice
                        value={EOccupationalArea.INDUSTRY_CITY}
                        label="Industry City"
                        icon="city"
                      />
                      <Choice
                        value={EOccupationalArea.INSTALLATION_SERVICES}
                        label="Installation Services"
                        icon="box"
                      />
                      <Choice
                        value={EOccupationalArea.CORPORATE}
                        label="Corporate"
                        icon="tie"
                      />
                    </Pick>
                    {touched.occupationalArea && errors.occupationalArea && (
                      <SubHeading error>{errors.occupationalArea}</SubHeading>
                    )}
                  </Col>
                </Row>
                {values.occupationalArea === EOccupationalArea.STORES && (
                  <Row>
                    <Col tablet="6">
                      <TextField
                        expand
                        name="storeNumber"
                        label="Store Number *"
                        value={values.storeNumber}
                        error={touched.storeNumber && errors.storeNumber}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>
                )}
              </Section>

              <Section>
                <ScrollLocation
                  innerRef={this.addRef("scrollRefs", "issueType")}
                />
                <Heading>Issue type *</Heading>
                <Pick
                  name="issueType"
                  value={values.issueType}
                  handleChange={setFieldValue}
                >
                  <Choice
                    value={EIssueType.DEFECT}
                    label="Defect"
                    icon="defect"
                    subtitle="Used to report defects, damage and durability issues"
                  />
                  <Choice
                    value={EIssueType.DESIGN}
                    label="Function, Design, Depiction"
                    icon="couch"
                    subtitle="Product is first-quality, but have an idea to improve customer experience"
                  />
                  <Choice
                    value={EIssueType.ASSEMBLY}
                    label="Assembly"
                    icon="widgets"
                    subtitle="Product is first-quality, but assembly is difficult, instructions unclear"
                  />
                </Pick>
                {touched.issueType && errors.issueType && (
                  <SubHeading error>{errors.issueType}</SubHeading>
                )}
              </Section>

              {!values.issueType && (
                <React.Fragment>
                  <Section />
                  <Section />
                  <Section />
                  <Section />
                  <Section />
                </React.Fragment>
              )}

              {values.issueType !== null && (
                <div>
                  <Section>
                    <Heading>Product details</Heading>
                    <Row>
                      <Col mobile="8" tablet="6">
                        <ScrollLocation
                          innerRef={this.addRef("scrollRefs", "sku")}
                        />
                        <TextField
                          expand
                          name="sku"
                          label="SKU *"
                          value={values.sku}
                          error={touched.sku && errors.sku}
                          handleBlur={async e => {
                            handleBlur(e);
                            if (!e.target.value) {
                              return;
                            }
                            try {
                              const skuDescription = await this.fetchSkuDescription(
                                e.target.value
                              );
                              if (!skuDescription) {
                                this.setState({ isValidSku: false });
                                setFieldValue("skuDescription", ""); //Aditya: Added to clear the SKU description
                              } else {
                                this.setState({ isValidSku: true });
                                setFieldError("sku", "");
                                setFieldValue("skuDescription", skuDescription);
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          handleChange={handleChange}
                        />
                        <Note>
                          A SKU is a set of numbers that can be located on the
                          outside of the carton or on a product's barcode label.
                        </Note>
                      </Col>
                      <Col mobile="4" tablet="4">
                        <Button
                          handleClick={() => {
                            const url = this.state.isLiveStream
                              ? "/issue/scan"
                              : "/issue/scan-static";
                            this.props.history.push(url);
                          }}
                          label="Scan barcode"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col tablet="6">
                        <ScrollLocation
                          innerRef={this.addRef("scrollRefs", "skuDescription")}
                        />
                        <TextField
                          expand
                          multiline
                          name="skuDescription"
                          label="SKU Description *"
                          value={values.skuDescription}
                          error={
                            touched.skuDescription && errors.skuDescription
                          }
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                        />
                      </Col>
                    </Row>
                    {values.issueType === EIssueType.DEFECT && (
                      <Row>
                        <Col tablet="6">
                          <TextField
                            expand
                            name="purchaseOrderNo"
                            label="Purchase order number"
                            value={values.purchaseOrderNo}
                            error={
                              touched.purchaseOrderNo && errors.purchaseOrderNo
                            }
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                          />
                          <Note>
                            Purchase Order Number can be located outside of
                            carton if available
                          </Note>
                        </Col>
                      </Row>
                    )}
                  </Section>

                  <Section>
                    <Heading>Issue details</Heading>

                    {values.issueType === EIssueType.DEFECT && (
                      <React.Fragment>
                        <Expand background="#fafafa">
                          <ScrollLocation
                            innerRef={this.addRef(
                              "scrollRefs",
                              "defectClassification"
                            )}
                          />
                          <Contract>
                            <SubHeading
                              error={
                                touched.defectClassification &&
                                errors.defectClassification
                              }
                            >
                              Defect classification *
                            </SubHeading>
                          </Contract>
                          <LayerPick
                            name="defectClassification"
                            value={values.defectClassification}
                            error={
                              touched.defectClassification &&
                              errors.defectClassification
                            }
                            handleChange={setFieldValue}
                          >
                            <Pick label="Primary category">
                              {getLayerData(
                                this.props.data.defectCategories,
                                values.defectClassification.slice(0, 0)
                              ).map(category => (
                                <RadioChoice
                                  value={category.id}
                                  label={category.label}
                                />
                              ))}
                            </Pick>
                            <Pick label="Secondary category">
                              {getLayerData(
                                this.props.data.defectCategories,
                                values.defectClassification.slice(0, 1)
                              ).map(category => (
                                <RadioChoice
                                  value={category.id}
                                  label={category.label}
                                />
                              ))}
                            </Pick>
                            <Pick label="Tertiary category">
                              {getLayerData(
                                this.props.data.defectCategories,
                                values.defectClassification.slice(0, 2)
                              ).map(category => (
                                <RadioChoice
                                  value={category.id}
                                  label={category.label}
                                />
                              ))}
                            </Pick>
                          </LayerPick>
                        </Expand>
                        <Expand back background="#fafafa">
                          <Row>
                            <Col mobile="4" tablet="3">
                              <TickAlign>
                                <Tick
                                  name="isOther"
                                  label="Other *"
                                  value={this.state.isOther}
                                  handleChange={this.setFieldToState}
                                />
                              </TickAlign>
                            </Col>
                            <Col mobile="8" tablet="9">
                              {this.state.isOther && (
                                <React.Fragment>
                                  <ScrollLocation
                                    innerRef={this.addRef(
                                      "scrollRefs",
                                      "other"
                                    )}
                                  />
                                  <TextField
                                    expand
                                    name="other"
                                    label="In your own words, classify the issue"
                                    value={values.other}
                                    error={touched.other && errors.other}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                  />
                                </React.Fragment>
                              )}
                            </Col>
                          </Row>
                        </Expand>
                      </React.Fragment>
                    )}

                    <Row>
                      <Col>
                        <ScrollLocation
                          innerRef={this.addRef("scrollRefs", "description")}
                        />
                        <TextField
                          multiline
                          expand
                          name="description"
                          label="Describe the issue *"
                          value={values.description}
                          error={touched.description && errors.description}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                        />
                      </Col>
                    </Row>
                    {values.issueType === EIssueType.DEFECT && (
                      <Row>
                        <Col>
                          <Tick
                            name="RepeatIssue"
                            label="I have seen the issue before"
                            value={values.RepeatIssue}
                            handleChange={setFieldValue}
                          />
                        </Col>
                      </Row>
                    )}
                    {values.issueType === EIssueType.DEFECT && (
                      <Row>
                        <Col>
                          <Tick
                            name="Return"
                            label="Customer return"
                            value={values.Return}
                            handleChange={setFieldValue}
                          />
                        </Col>
                      </Row>
                    )}
                    {values.issueType === EIssueType.DEFECT && (
                      <Row>
                        <Col>
                          <ScrollLocation
                            innerRef={this.addRef("scrollRefs", "impact")}
                          />
                          <SubHeading error={touched.impact && errors.impact}>
                            How do you think a customer would react? *
                          </SubHeading>
                          <Pick
                            name="impact"
                            value={values.impact}
                            handleChange={setFieldValue}
                          >
                            <RadioChoice
                              value={EImpact.CALM}
                              icon="face-straight"
                              label="Calm, Not a pressing issue"
                            />
                            <RadioChoice
                              value={EImpact.ANNOYED}
                              icon="face-frown"
                              label="Annoyed, This is bothersome"
                            />
                            <RadioChoice
                              value={EImpact.DISAPPOINTED}
                              icon="face-disappointed"
                              label="Disappointed, This is a letdown"
                            />
                            <RadioChoice
                              value={EImpact.INFURIATED}
                              icon="face-angry"
                              label="Infuriated, this is an unacceptable situation"
                            />
                          </Pick>
                          {touched.impact && errors.impact && (
                            <SubHeading error>{errors.impact}</SubHeading>
                          )}
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <SubHeading>Attachments</SubHeading>
                        <Attachment
                          to="/attachments"
                          onRemove={key =>
                            this.props.removeAttachment(key).then(() =>
                              setFieldValue(
                                "attachments",
                                values.attachments.filter(file => {
                                  return file.indexOf(key) === -1;
                                })
                              )
                            )
                          }
                          onStart={() =>
                            this.setState({
                              isUploading: this.state.isUploading + 1
                            })
                          }
                          onUpload={fileLocations => {
                            setFieldValue(
                              "attachments",
                              values.attachments.concat(fileLocations)
                            );
                            this.setState({
                              isUploading: this.state.isUploading - 1
                            });
                          }}
                        />
                        <Note>
                          Please submit two photos with at least one close-up of
                          the product issue
                        </Note>
                      </Col>
                    </Row>
                  </Section>

                  <Section>
                    <Heading>Suggestion for improvement</Heading>
                    <Row>
                      <Col>
                        <TextField
                          multiline
                          expand
                          name="suggestion"
                          label="Your suggestion"
                          value={values.suggestion}
                          error={touched.suggestion && errors.suggestion}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {this.state.isSaveErr && (
                        <Col>
                          Oops something went wrong.
                          <br />
                          Retry or notify the team using the button below.
                        </Col>
                      )}
                      <Col>
                        <Button
                          primary
                          disabled={
                            this.state.isUploading !== 0 || this.state.isSaving
                          }
                          handleClick={() => this.scrollToInvalid(errors)}
                          type="submit"
                          label={this.state.isSaveErr ? "Retry" : "Send"}
                        />
                        {this.state.isSaveErr && (
                          <Button
                            primary
                            handleClick={this.notify.bind(
                              this,
                              values,
                              this.state.isSaveErr
                            )}
                            label="Notify Team"
                          />
                        )}
                      </Col>
                    </Row>
                  </Section>
                </div>
              )}
              <Route
                path="/issue/scan"
                render={routerProps => (
                  <FullOverlay>
                    <IssueBar>
                      <Link to="/issue">
                        <Icon name="back" />
                      </Link>
                      <IssueTitle>Scan barcode</IssueTitle>
                    </IssueBar>
                    <BarcodeScanner
                      onDetect={this.setSkuValue(
                        setFieldValue,
                        setFieldError,
                        setFieldTouched
                      )}
                    />
                  </FullOverlay>
                )}
              />
              <Route
                path="/issue/scan-static"
                render={() => (
                  <FullOverlay>
                    <IssueBar>
                      <Link to="/issue">
                        <Icon name="back" />
                      </Link>
                      <IssueTitle>Scan barcode</IssueTitle>
                    </IssueBar>
                    <BarcodeScannerStatic
                      onDetect={this.setSkuValue(
                        setFieldValue,
                        setFieldError,
                        setFieldTouched
                      )}
                      onFailDetect={() => console.log("Fail detect")}
                      failDetectStatus={false}
                      onDetectName={() => console.log("Detect name")}
                    />
                  </FullOverlay>
                )}
              />
              <Prompt
                when={!this.state.isSaving && dirty}
                message={location =>
                  location.pathname.includes("/issue")
                    ? true
                    : "Exit and Delete Content?"
                }
              />
            </form>
          )}
        />
      </Wrapper>
    );
  }
}

const IssueBar = createComponent(() => ({
  height: "52px",
  padding: "12px 6px",
  background: "#e3e0d7",
  lineHeight: "36px",
  textAlign: "center",
  position: "relative",

  "> a": {
    position: "absolute",
    left: "29px",
    top: "15px"
  }
}));

const TickAlign = createComponent(() => ({
  paddingTop: "17px"
}));

const IssueTitle = createComponent(
  () => ({
    paddingLeft: "6px",
    opacity: "0.6"
  }),
  "span"
);

export default compose(
  graphql(DefectCategoriesQuery, {
    alias: "withDefectCategories"
  }),
  graphql(AddIssueMutation, {
    alias: "withAddIssue",
    props: ({ mutate }, ...ownProps) => ({
      addIssueAction: issue =>
        mutate({
          variables: {
            issue
          }
        })
    })
  }),
  graphql(NotifyTeamMutation, {
    props: ({ mutate }) => ({
      notifyTeam: (issue, error) =>
        mutate({
          variables: {
            issue,
            error
          }
        })
    })
  }),
  graphql(RemoveAttachmentMutation, {
    props: ({ mutate }) => ({
      removeAttachment: key =>
        mutate({
          variables: {
            key
          }
        })
    })
  })
)(Issue);
