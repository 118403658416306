import gql from 'graphql-tag'

export const GetIssueQuery = gql`
  query DoGetIssueQuery($id: ID!) {
    getIssue(id: $id) {
      _id
      attachments
      name
    }
  }
`
