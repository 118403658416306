import * as React from 'react'
import { Query } from 'react-apollo'
import { createComponent } from 'react-fela'
import Button from '../../components/Button/Button'
import { GetIssueQuery } from '../../graphql/GetIssueQuery.graphql'

const Attachments = (props) => {
  const issueId = props.location.pathname.split('/')[2]
  return (
    <div>
      <Query query={GetIssueQuery} variables={{ id: issueId }}>
        {({ loading, data: { getIssue }, error }) => {
          if (loading) { return 'Loading...' }
          if (error) { return error.message }
          const images = getIssue.attachments.map((attachment) => (
            <ImageWrapper>
              <Image>
                <img src={attachment} />
              </Image>
              <Button label="Download" handleClick={() => downloadImage(attachment)} />
            </ImageWrapper>
          ))
          return (
            <Wrapper>
              <h3>Images uploaded by {getIssue.name}</h3>
              {images}
            </Wrapper>
          )
        }}
      </Query>
    </div>
  )
}

const downloadImage = (url) => window.open(url)

const ImageWrapper = createComponent(() => ({
  float: 'left',
}))

const Image = createComponent((props) => ({
  marginTop: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '200px',
  height: '200px',
  background: '#ccc',
  border: '5px solid #ccc',
  marginRight: '5px',
  '> img': {
    maxWidth: '100%',
    maxHeight: '100%',
  }
}), 'span')

const Wrapper = createComponent(() => ({
  padding: '20px',
}))

export default Attachments
