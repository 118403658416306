import * as React from 'react'
import * as request from 'superagent'
import { createComponent, createComponentWithProxy } from 'react-fela'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import { Formik } from 'formik'
import Wrapper from '../../components/Grid/Wrapper'
import Section from '../../components/Section/Section'
import Row from '../../components/Grid/Row'
import Col from '../../components/Grid/Col'
import Button from '../../components/Button/Button'
import Heading from '../../components/Heading/Heading'
import SubHeading from '../../components/SubHeading/SubHeading'
import Icon from '../../components/Icon/Icon'
import DateField from '../../components/DateField/DateField'
import Loader from '../../components/Loader/Loader'
import { isDate } from '../../../universal/utils'

interface IState {
  uploadSuccess: boolean
  uploadError: boolean
  loading: boolean
}

class Admin extends React.Component<IState> {
  // validate(values) {
  //   const errors = {}
  //   if (values.from && !isDate(values.from)) {
  //     errors.from = 'Starting date is invalid. Please input date as mm/dd/yyyy'
  //   }
  //   if (values.until && !isDate(values.until)) {
  //     errors.until = 'Until date is invalid. Please input date as mm/dd/yyyy'
  //   }
  //   return errors
  // }

  constructor(props) {
    super(props)
    this.state = {
      uploadSuccess: false,
      uploadError: false,
      loading: false,
    }
  }

  resetUpload() {
    setTimeout(() => {
      this.setState({
        ...this.state,
        uploadSuccess: false,
        uploadError: false,
      })
    }, 5000)
  }

  uploadCategories(acceptedFiles, rejectedFiles) {
    const fileRequest = request.post('/upload-categories')
    if (acceptedFiles[0]) {
      this.setState({
        ...this.state,
        loading: true,
      })
      fileRequest.attach('csvfile', acceptedFiles[0])
      fileRequest.then((res) => {
        this.props.data.refetch()
        this.setState({
          uploadSuccess: true,
          uploadError: false,
          loading: false,
        }, this.resetUpload)
      }).catch((err) => {
        this.setState({
          uploadSuccess: false,
          uploadError: true,
          loading: false,
        }, this.resetUpload)
      })
    }
  }

  renderDefectCategories() {
    return this.props.data.loading
      ? 'Loading'
      : (
        <Section>
          <Heading>Defect categories</Heading>
          <Row>
            <Col>
              <p>Upload a CSV file with the updated defect categories</p>
            </Col>
          </Row>
          <Row>
            <Col mobile="6">
              <DZoneStyle>
                {this.state.loading
                  ? <Position><Loader /></Position>
                  : <Dropzone onDrop={this.uploadCategories.bind(this)}>
                    {this.state.uploadSuccess && 'Upload successful'}
                    {this.state.uploadError && 'Upload failed'}
                    {!this.state.uploadSuccess && !this.state.uploadError && 'Add new'}
                  </Dropzone>}

              </DZoneStyle>
            </Col>
          </Row>
          <Button
            label="Download Current CSV"
            handleClick={() => window.open(this.props.data.originalCSV)} />
        </Section>
      )
  }

  render() {
    return (
      <Wrapper className={this.props.className}>
        <IssueBar>
          <Link to="/">
            <Icon name="back" />
          </Link>
          <IssueTitle>Admin</IssueTitle>
        </IssueBar>
        <Section>
          <Heading>Reports</Heading>
          <Formik
            initialValues={{
              from: '',
              until: '',
            }}
            onSubmit={(values) => {
              const fromIso = values.from &&
                '&from=' + new Date(values.from).toISOString()
              const untilIso = values.until &&
                '&until=' + new Date(values.until).toISOString()
              window.open(`/download-report?something=true${fromIso}${untilIso}`)
            }}
            render={({
              values,
              touched,
              errors,
              setFieldValue,
              handleChange,
              handleSubmit
            }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <p>Here you can download a CSV file with all the issues reported so far</p>
                    </Col>
                    <Col>
                      <DateField
                        name="from"
                        label="From"
                        value={values.from}
                        error={touched.from && errors.from}
                        handleChange={handleChange} />
                    </Col>
                    <Col>
                      <DateField
                        name="until"
                        label="Until"
                        value={values.until}
                        error={touched.until && errors.until}
                        handleChange={handleChange} />
                    </Col>
                    <Col>
                      <Button
                        type="submit"
                        label="Download report" />
                    </Col>
                  </Row>
                </form>
              )}
          />
        </Section>
        {this.renderDefectCategories()}
      </Wrapper>
    )
  }
}

const IssueBar = createComponent(() => ({
  height: '52px',
  padding: '12px 6px',
  background: '#e3e0d7',
  lineHeight: '36px',
  textAlign: 'center',
  position: 'relative',

  '> a': {
    position: 'absolute',
    left: '29px',
    top: '15px',
  }
}))

const IssueTitle = createComponent(() => ({
  paddingLeft: '6px',
  opacity: '0.6',
}), 'span')

const DZoneStyle = createComponentWithProxy((props) => ({
  '> div': {
    width: '100%',
    height: '130px',
    textAlign: 'center',
    lineHeight: '120px',
    position: 'relative',
  }
}))

const Position = createComponent(() => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '30px',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  background: '#fff'
}))

export default graphql(gql`query {
  originalCSV
}`)(Admin)
