import * as React from 'react'
import classnames from 'classnames'

import './style.scss'

interface IProps {
  message: string,
  type: string,
  align?: string,
  modifier?: string,
  marginTop?: number,
  marginBottom?: number,
}

class InfoMessage extends React.Component<IProps> {

  static defaultProps = {
    message: '',
    type: '',
    align: '',
    modifier: '',
    marginTop: 0,
    marginBottom: 0,
  }

  render() {
    const { message, type, align, modifier, marginTop, marginBottom } = this.props
    const containerStyle: any = {}
    if (marginTop) {
      containerStyle.marginTop = `${marginTop}px`
    }
    if (marginBottom) {
      containerStyle.marginBottom = `${marginBottom}px`
    }
    const containerClass = classnames('info-message__inner', {
      [`info-message__inner--${type}`]: type,
      [`info-message__inner--align-${align}`]: align
    })

    return (
      <div style={containerStyle} className={`info-message ${modifier ? `info-message--${modifier}` : ''}`}>
        <div className={containerClass}>
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      </div>
    )
  }
}

export default InfoMessage
