import * as React from 'react'
import { Route, Link, Redirect } from 'react-router-dom'
import { graphql, compose } from 'react-apollo'
import Home from '../Home/Home'
import Issue from '../Issue/Issue'
import Admin from '../Admin/Admin'
import AppBar from '../../components/AppBar/AppBar'
import Attachments from '../Attachments/Attachments'

import { CurrentUserQuery } from '../../graphql/CurrentUserQuery.graphql'
import { LogoutMutation } from '../../graphql/LogoutMutation.graphql'

interface IProps {
  logout: Function
}

interface IState {
  message?: string
}

class App extends React.Component<IProps, IState> {
  render() {
    return (
      <React.Fragment>
        {this.props.data.loading
          ? <div>Loading</div>
          : <React.Fragment>
            <Route path="/" exact render={() => (
              <Home
                isAuthenticated={this.props.data.currentUser.isAuthenticated}
                logout={this.props.logout}
                message={this.props.location.state && this.props.location.state.message} />
            )} />
            <Route path="/issue" component={Issue} />
            <Route path="/admin" render={() => {
              if (this.props.data.currentUser.isAuthenticated) {
                return <Admin />
              } else {
                window.location.replace('/login')
              }
            }} />
            <Route path="/attachments" component={Attachments} />
          </React.Fragment>
        }

      </React.Fragment>
    )
  }
}

export default compose(
  graphql(CurrentUserQuery, {
    alias: 'withCurrentUser',
    options: {
      variables: {
        token: localStorage.getItem('SAMLResponse') || ''
      }
    }
  }),
  graphql(LogoutMutation, {
    alias: 'withLogout',
    props: ({mutate}) => ({
      logout: () => {
        localStorage.removeItem('SAMLResponse')
        return mutate({})
      }
    })
  })
)(App)
