import * as React from 'react'
import { createComponentWithProxy, createComponent } from 'react-fela'
import Pick from '../Pick/Pick'
import Choice from '../Pick/Choice'
import Card from '../Card/Card'
import TextPlaceholder from '../TextPlaceholder/TextPlaceholder'
import SubHeading from '../SubHeading/SubHeading'

interface IProps {
  value: string[]
  disabled: boolean
  children: any
  handleChange: any
  name?: string
  className?: string
}

class LayerPick extends React.Component<IProps> {
  scrollContainer: any
  widthContainer: any
  withStyle(Component, depth) {
    return (
      <Column depth={depth}>
        <Card title={Component.props.label} height="300">
          {Component}
        </Card>
      </Column>
    )
  }

  render() {
    const pickers = React.Children.toArray(this.props.children).map((Pick, index, all) => {
      return React.cloneElement(Pick, {
        name: this.props.name,
        handleChange: (name, value) => {
          this.scrollContainer.scrollLeft = this.widthContainer.offsetWidth / all.length * (index + 1)
          this.props.handleChange(
            name,
            this.props.value.slice(0, index).concat(value)
          )
        },
        value: this.props.value[index],
        disabled: this.props.disabled,
      })
    })
    const selectedPickers = pickers
      .filter((Pick) => !!Pick.props.value)
      .map((Pick) => this.withStyle(Pick, pickers.length))
    const current = pickers.find((Pick) => !Pick.props.value)
    const currentWithStyle = current && this.withStyle(current, pickers.length)
    const currentIndex = pickers.indexOf(current)
    const hasNext = currentIndex !== -1 && currentIndex < pickers.length - 1
    const nextPlaceholder = hasNext && (
      <Column depth={pickers.length}>
        <Card title={pickers[currentIndex + 1].props.label} height="300">
          <TextPlaceholder />
        </Card>
      </Column>
    )

    return (
      <div
        className={this.props.className}
        ref={(ref) => this.scrollContainer = ref}>
        {this.props.error &&
          <Error>{this.props.error}</Error>
        }
        <CardWindow
          depth={pickers.length}
          innerRef={(ref) => this.widthContainer = ref}>
          {selectedPickers}
          {currentWithStyle}
          {nextPlaceholder}
        </CardWindow>
      </div>
    )
  }
}

const Error = createComponentWithProxy((props) => ({
  fontSize: '11px',
  color: props.theme.errorColor,
  padding: '0 30px',
}))

const Column = createComponentWithProxy(({ depth }) => ({
  float: 'left',
  width: 100 / depth + '%',
  paddingLeft: '15px',
  paddingRight: '15px',
}))

const CardWindow = createComponentWithProxy(({ depth }) => ({
  overflow: 'hidden',
  width: 80 * depth + '%',
  padding: '10px 15px 15px 15px',

  tablet: {
    width: '100%',
  }
}))

export default createComponentWithProxy(() => ({
  overflow: 'auto',
}), LayerPick)
