import * as React from 'react'
import * as request from 'superagent'
import Dropzone from 'react-dropzone'
import { createComponentWithProxy, createComponent } from 'react-fela'
import { rotateImage } from '../../../universal/utils'
import * as loadImage from 'blueimp-load-image'
// import DropzoneIos from '../DropzoneIos/DropzoneIos'
import Row from '../Grid/Row'
import Col from '../Grid/Col'
import Loader from '../Loader/Loader'
import Icon from '../Icon/Icon'

interface IProps {
  to: string
  onUpload: Function
  onRemove?: Function
  onStart?: Function
  className?: string
}

interface IState {
  accepted: any[]
  rejected: any[]
  error: String
}

const doParseMetaData = async (file: File) =>
  new Promise((resolve, reject) => loadImage.parseMetaData(file, resolve))

const doLoadImage = async (file: File) =>
  new Promise((resolve, reject) => loadImage(file, resolve))

const fixRotation = async (file: File) => {
  const metadata = await doParseMetaData(file)
  let rotatedImage
  if (metadata.exif) {
    switch (metadata.exif.get('Orientation')) {
      case 8:
        rotatedImage = rotateImage(await doLoadImage(file), -90)
        break
      case 3:
        rotatedImage = rotateImage(await doLoadImage(file), 180)
        break
      case 6:
        rotatedImage = rotateImage(await doLoadImage(file), 90)
        break
    }
  }
  return rotatedImage
}

const flipAll = async (files) => Promise.all(files.map(fixRotation))

class Attachment extends React.Component<IProps, IState> {

  constructor(props) {
    super(props)
    this.state = {
      accepted: [],
      rejected: [],
      error: '',
    }
  }

  async onDrop(acceptedFiles, rejectedFiles) {
    const fileRequest = request.post('/attachments')
    const flipped = await flipAll(acceptedFiles)

    acceptedFiles.forEach((file, i) => {
      file.loading = true
      acceptedFiles[i].preview = flipped[i]
    })
    this.setState({
      accepted: this.state.accepted.concat(acceptedFiles),
      rejected: this.state.rejected.concat(rejectedFiles),
    }, this.props.onStart)
    acceptedFiles.forEach((file) => fileRequest.attach('photos', file))
    fileRequest.then((res) => {
      const response = JSON.parse(res.body)
      this.props.onUpload(response.map((file) => file.location))
      this.setState({
        ...this.state,
        accepted: this.state.accepted.map((file, i) => {
          const uploadedFile = response.find((uploadedFile) => uploadedFile.name === file.name)
          if (uploadedFile) {
            file.loading = false
            file.key = uploadedFile.key
            file.preview = uploadedFile.location
          }
          return file
        })
      })
    }).catch((err) => {
      this.setState({
        ...this.state,
        error: err.message,
      })
    })
  }

  onRemove(image) {
    this.props.onRemove(image.key)
    this.setState({
      ...this.state,
      accepted: this.state.accepted.filter((file) => file.key !== image.key)
    })
  }

  render() {
    const acceptedFiles = this.state.accepted.map((image) => {
      return (
        <Col mobile="6" tablet="4">
          <Wrapper>
            <RemoveButton onClick={() => this.onRemove(image)}>
              <img src="images/close.png" width="24" height="24" />
            </RemoveButton>
            {image.loading && <Position><Loader /></Position>}
            <Image src={image.preview} />
          </Wrapper>
        </Col>
      )
    })
    return (
      <React.Fragment>
        <Row className={this.props.className}>
          {acceptedFiles}
          {this.state.accepted.length === 0 &&
            <Col mobile="6" tablet="4">
              <DZoneStyle>
                <Dropzone onDrop={this.onDrop.bind(this)}>
                  Add new
              </Dropzone>
              </DZoneStyle>
            </Col>
          }
          <Col mobile="6" tablet="4">
            <DZoneStyle>
              <Dropzone onDrop={this.onDrop.bind(this)}>
                Add new
              </Dropzone>
            </DZoneStyle>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.error &&
              <Error>{this.state.error}</Error>
            }
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const Error = createComponent((props) => ({
  fontSize: '11px',
  color: props.theme.errorColor,
}))

const Wrapper = createComponent(() => ({
  position: 'relative',
}))

const RemoveButton = createComponentWithProxy(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: '10px',
  '> img': {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    padding: '2px',
    background: '#ccc',
  }
}))

const Position = createComponent(() => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '30px',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  background: '#fff'
}))

const Image = createComponentWithProxy((props) => ({
  width: '100%',
  height: '130px',
  backgroundImage: `url(${props.src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '5px',
}))

const DZoneStyle = createComponentWithProxy((props) => ({
  '> div': {
    width: '100%',
    height: '130px',
    textAlign: 'center',
    lineHeight: '120px',
  }
}))

export default Attachment
