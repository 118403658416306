import gql from 'graphql-tag'
import { UserFragment } from './UserFragment.graphql'

export const CurrentUserQuery = gql`
  query CurrentUserQuery($token: String!) {
    currentUser(token: $token) {
      ...UserFragment
    }
  }
  ${UserFragment}
`
