import * as React from 'react'
import { createComponentWithProxy } from 'react-fela'

interface IProps {
  name?: string
  handleChange?: Function
  disabled?: boolean
  value?: string
  tabIndex?: string
}

class Pick extends React.Component<IProps> {
  render() {
    const choices = React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        name: this.props.name,
        onClick: this.props.disabled
          ? () => {}
          : (e) => {
            this.props.handleChange(this.props.name, child.props.value)
          },
        disabled: this.props.disabled,
        selected: this.props.value === child.props.value,
        count: React.Children.count(this.props.children),
      })
    )
    return (
      <div className={this.props.className} tabIndex={this.props.tabIndex}>
        {choices}
      </div>
    )
  }
}

export default createComponentWithProxy((props) => ({
  display: 'block',
  overflow: 'hidden',
}), Pick)
