import * as React from "react";
import { createComponent, createComponentWithProxy } from "react-fela";

interface IProps {
  label: string;
  name: string;
  handleChange: Function;
  handleBlur: Function;
  description?: string;
  value?: string;
  error?: string;
  expand?: boolean;
  disabled?: boolean;
  multiline?: boolean;
}
interface IState {
  isFocused: boolean;
}

export class TextField extends React.Component<IProps, IState> {
  inputEl: HTMLElement;

  constructor(props) {
    super(props);
    this.state = {
      isFocused: false
    };
  }

  onFocus() {
    this.setState(
      Object.assign({}, this.state, {
        isFocused: true
      })
    );
  }

  onBlur() {
    this.setState(
      Object.assign({}, this.state, {
        isFocused: false
      })
    );
  }

  componentDidMount() {
    this.inputEl.addEventListener("focus", this.onFocus.bind(this));
    this.inputEl.addEventListener("blur", this.onBlur.bind(this));
  }

  componentWillUnmount() {
    this.inputEl.removeEventListener("focus", this.onFocus);
    this.inputEl.removeEventListener("blur", this.onBlur);
  }

  renderInput() {
    if (this.props.multiline) {
      return (
        <Textarea
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          innerRef={inputEl => (this.inputEl = inputEl)}
          {...this.state}
          {...this.props}
        />
      );
    } else {
      return (
        <Input
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          innerRef={inputEl => (this.inputEl = inputEl)}
          {...this.state}
          {...this.props}
        />
      );
    }
  }

  renderDescription() {
    if (this.props.error) {
      return <Error>{this.props.error}</Error>;
    }
    if (this.props.description) {
      return <Description>{this.props.description}</Description>;
    }
  }

  render() {
    return (
      <Root {...this.state} error={this.props.error} expand={this.props.expand}>
        <FakeLabel {...this.state} {...this.props}>
          {this.props.label}
        </FakeLabel>
        {this.renderInput()}
        {this.renderDescription()}
      </Root>
    );
  }
}

const Description = createComponent(props => ({
  fontSize: "11px",
  color: "rgba(0, 0, 0, .4)",
  position: "absolute",
  bottom: 0,
  left: 0
}));

const Error = createComponent(props => ({
  fontSize: "11px",
  color: props.theme.errorColor,
  position: "absolute",
  bottom: 0,
  left: 0
}));

const FakeLabel = createComponent(
  (props: any) => ({
    display: "block",
    fontSize: "14px",
    color: "rgba(0, 0, 0, .4)",
    cursor: "text",
    transform: "translate(0, 21px) scale(1)",
    transformOrigin: "top left",
    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",

    extend: [
      {
        condition: props.isFocused,
        style: {
          transform: "translate(0, 1px) scale(0.78)",
          color: props.theme.secondaryColor
        }
      },
      {
        condition: props.value !== "",
        style: {
          transform: "translate(0, 1px) scale(0.78)"
        }
      },
      {
        condition: props.error,
        style: {
          color: props.theme.errorColor
        }
      }
    ]
  }),
  "span"
);

const inputStyle = props => ({
  display: "block",
  width: "100%",
  fontSize: "16px",
  color: "rgba(0, 0, 0, .7)",
  border: "0",
  background: "transparent",
  padding: "5px 0 5px 0",

  ":focus": {
    borderColor: props.theme.secondaryColor
  },

  ":hover": {
    cursor: "text"
  },

  extend: {
    condition: props.isFocused,
    style: {
      borderBottomWidth: "2px"
    }
  }
});

const Input = createComponent(
  props => ({
    ...inputStyle(props)
  }),
  "input",
  ["name", "value", "onChange", "onBlur"]
);

const Textarea = createComponent(
  props => ({
    ...inputStyle(props),
    width: "100%",
    minHeight: "60px"
  }),
  "textarea",
  ["name", "value", "onChange", "onBlur"]
);

const Root = createComponent(
  props => ({
    display: "inline-block",
    position: "relative",
    paddingBottom: "20px",

    ":after": {
      content: '" "',
      position: "absolute",
      background: "rgba(0, 0, 0, .5)",
      height: "1px",
      left: 0,
      right: 0,
      bottom: "19px"
    },

    extend: [
      {
        condition: props.isFocused,
        style: {
          ":after": {
            height: "2px",
            background: props.theme.secondaryColor
          }
        }
      },
      {
        condition: props.error,
        style: {
          ":after": {
            height: "2px",
            background: props.theme.errorColor
          }
        }
      },
      {
        condition: props.expand,
        style: {
          width: "100%"
        }
      }
    ]
  }),
  "label"
);

export default TextField;
