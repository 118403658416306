import { createRenderer } from 'fela'
import namedMediaQuery from 'fela-plugin-named-media-query'
import important from 'fela-plugin-important'
import extend from 'fela-plugin-extend'
import { initTheme } from './themes/default'

const responsive = namedMediaQuery({
  tablet: '@media (min-width: 666px)',
  desktop: '@media (min-width: 1024px)',
})

const renderer = createRenderer({
  selectorPrefix: 'we-',
  plugins: [
    responsive,
    extend(),
    important()
  ]
})

initTheme(renderer)

export default renderer
