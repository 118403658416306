import * as React from 'react'
import { createComponent, createComponentWithProxy } from 'react-fela'
import Icon from '../Icon/Icon'

interface IProps {
  name: string
  value: any
  label: string
  handleChange: any
}

const Tick = (props: IProps) => (
  <div
    className={props.className}
    onClick={(e) => props.handleChange(props.name, !props.value)}>
    <span>
      {!!props.value
        ? <ColoredIcon name="check-checked" />
        : <ColoredIcon name="check" />
      }
    </span>
    <div>{props.label}</div>
  </div>
)

const ColoredIcon = createComponentWithProxy((props) => ({
  '> path': {
    fill: 'rgba(0, 0, 0, .7)'
  },
}), Icon)

export default createComponentWithProxy(() => ({
  cursor: 'pointer',
  lineHeight: '18px',
  display: 'inline-block',

  '> span': {
    float: 'left',
    marginRight: '5px',
  },

  '> div': {
    display: 'inline-block',
    paddingTop: '3px',
  }
}), Tick)
