import * as React from 'react'
import { createComponent, createComponentWithProxy } from 'react-fela'
import { Link } from 'react-router-dom'

interface IProps {
  isAuthenticated: boolean
  logout: Function
  linkTo?: string
  fixed?: boolean
}

const AppBar = (props) => (
  <div className={props.className}>
    <UserArea>
      {props.isAuthenticated
        ? <React.Fragment>
          <Spacer><Link to="/admin">Reports</Link></Spacer>
          <a href="#" onClick={() => props.logout()}>Admin Logout</a>
        </React.Fragment>
        : <Link to="/admin">Admin Login</Link>}
    </UserArea>
    <Link to={props.linkTo || '/'}>
      <Logo>
        {props.children}
      </Logo>
    </Link>
  </div>
)

const UserArea = createComponent(() => ({
  float: 'right'
}))

const Logo = createComponent(() => ({
  '> img': {
    maxHeight: '22px',
  }
}))

const Spacer = createComponent(() => ({
  marginRight: '15px',
}), 'span')

export default createComponentWithProxy((props: IProps) => ({
  height: '52px',
  padding: '12px',
  background: '#e3e0d7',
  lineHeight: '36px',

  '> a': {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, .4)'
  },

  extend: {
    condition: props.fixed,
    style: {
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      zIndex: 2,
    }
  }
}), AppBar)
