import * as React from 'react'
import { createComponent } from 'react-fela'

export default createComponent((props) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 2,

  desktop: {
    position: 'static',
    maxWidth: '1024px',
    margin: 'auto',
  }
}))
