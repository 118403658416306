import * as React from 'react'
import { createComponentWithProxy, createComponent } from 'react-fela'

interface IProps {
  label: string
  primary?: boolean
  disabled?: boolean
  type?: string
  className?: string
  handleClick?: any
}

const Button = (props: IProps) => (
  <button
    disabled={props.disabled}
    onClick={(e) => {
      if (props.type !== 'submit') { e.preventDefault() }
      if (!props.disabled && props.handleClick) {
        props.handleClick()
      }
    }}
    className={props.className}
    type={props.type}>
    {props.label}
  </button>
)

export default createComponentWithProxy((props) => ({
  display: 'inline-block',
  border: 'none',
  padding: '12px 20px',
  marginRight: '10px',
  fontSize: '12px',
  background: props.theme.secondaryColor,
  color: '#fff',
  cursor: 'pointer',

  ': hover': {

  },

  extend: [{
    condition: props.primary,
    style: {
      fontSize: '16px',
      padding: '14px 30px',
    }
  }, {
    condition: props.disabled,
    style: {
      background: 'rgba(0, 0, 0, .2)'
    }
  }]
}), Button, ['disabled'])
