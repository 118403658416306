import * as React from 'react'
import { createComponentWithProxy } from 'react-fela'

const Card = (props) => (
  <div className={props.className}>
    {props.title && <h5>{props.title}</h5>}
    <div>{props.children}</div>
  </div>
)

export default createComponentWithProxy((props) => ({
  background: '#fff',
  borderRadius: '5px',
  boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',

  '> h5': {
    height: '40px',
    lineHeight: '20px',
    padding: '10px 16px',
  },

  '> div': {
    height: props.height - 50 + 'px',
    overflow: 'auto',
    padding: '10px 16px',
  }
}), Card)
