import * as React from 'react'

const Loader = () => (
  <div className="loader hidden" id="loading-dots">
    <div className="loader__container">
      <span className="loader__bullet">
        <span className="loader__bullet-color"></span>
      </span>
      <span className="loader__bullet">
        <span className="loader__bullet-color"></span>
      </span>
      <span className="loader__bullet">
        <span className="loader__bullet-color"></span>
      </span>
      <span className="loader__bullet">
        <span className="loader__bullet-color"></span>
      </span>
    </div>
  </div>
)

export default Loader
