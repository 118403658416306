import * as React from 'react'
import { createComponent } from 'react-fela'

interface IProps {
  color?: string
  className?: string
}

const TextPlaceholder = (props: IProps) => (
  <div className={props.className}>
    <span>justeee</span>
    <span>Thbffdvis</span>
    <span>ifewious</span>
    <span>sogregrwegwregrewme</span>
    <span>tegrefiuewhxt</span>
    <span>plgregaceholder</span>
    <span>tharegregt</span>
    <span>erreglly</span>
    <span>dgreofvrefes</span>
    <span>nothing</span>
    <span>elergergse</span>
    <span>raergndom</span>
    <span>woergregrds</span>
    <span>next</span>
    <span>description</span>
    <span>thigregrenk?</span>
  </div>
)

export default createComponent((props) => ({
  fontSize: '10px',
  overflow: 'hidden',
  paddingTop: '12px',

  '> span': {
    float: 'left',
    marginBottom: '28px',
    marginRight: '10px',
    background: '#eee',
    color: '#eee',
    borderRadius: '5px',
    userSelect: 'none',
  },

  extend: {
    condition: props.color,
    style: {
      background: props.color,
      color: props.color
    }
  }
}), TextPlaceholder)
