import 'core-js'
import fetch from 'unfetch'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import { Provider as StateProvider } from 'react-redux'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import {
  Provider as FelaProvider,
  ThemeProvider as FelaThemeProvider,
} from 'react-fela'
import renderer from './styles/renderer'
import theme from './styles/themes/default'
import store from './state/store'
import history from './state/history'
import App from './containers/App/App'
import Issue from './containers/Issue/Issue'

declare const window: any

const mountNode = document.getElementById('stylesheet')
const isSecure = window.location.protocol.includes('https')
const wsProtocol = isSecure ? 'wss' : 'ws'
const wsUrl = `${wsProtocol}://${window.location.host}/subscriptions`
const wsClient = new SubscriptionClient(wsUrl, {
  reconnect: true
})

const hasSubscriptionOperation = ({ query: { definitions } }) =>
  definitions.some(
    ({ kind, operation }) =>
      kind === 'OperationDefinition' && operation === 'subscription'
  )

const client = new ApolloClient({
  link: ApolloLink.split(
    hasSubscriptionOperation,
    new WebSocketLink(wsClient),
    createHttpLink({
      uri: `${window.location.protocol}//${window.location.host}/graphql`,
      fetch,
    }),
  ),
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <StateProvider store={store}>
    <FelaProvider renderer={renderer} mountNode={mountNode}>
      <FelaThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <ConnectedRouter history={history}>
            <Route path="/" component={App} />
          </ConnectedRouter>
        </ApolloProvider>
      </FelaThemeProvider>
    </FelaProvider>
  </StateProvider>,
  document.getElementById('home')
)
