import * as React from 'react'
import { createComponent } from 'react-fela'

export default createComponent((props) => ({
  marginLeft: '-' + props.theme.bodyGutter,
  marginRight: '-' + props.theme.bodyGutter,
  paddingTop: '15px',

  extend: [{
    condition: props.back,
    style: {
      paddingLeft: props.theme.bodyGutter,
      paddingRight: props.theme.bodyGutter,
    }
  }, {
    condition: props.background,
    style: {
      background: props.background
    }
  }]
}))
