import * as React from 'react'
import { createComponentWithProxy } from 'react-fela'

interface IProps {
  name: string
  color?: string
  className?: string
}

const Icon = (props: IProps) => {
  switch (props.name) {
    case 'box':
      return (
        <svg className={props.className} width="24" height="24" viewBox="-2 -2 36 36">
          <path d="M1.735 17.832l12.054 6.081 2.152-6.081-12.053-5.758-2.153 5.758zM16.211 17.832l2.045 6.027 12.484-6.081-2.422-5.704-12.107 5.758zM-0.247 7.212l4.144 4.843 12.053-6.134-3.928-5.005-12.269 6.296zM32.247 7.319l-12.001-6.403-4.090 5.005 12.162 6.134 3.929-4.736zM3.175 19.353l-0.041 5.839 12.713 5.893v-10.98l-1.816 4.736-10.856-5.488zM16.291 20.105v10.979l12.674-5.893v-5.799l-10.99 5.46-1.684-4.747z"></path>
        </svg>
      )
    case 'city':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
          <path d="M18.984 15v-2.016h-1.969v2.016h1.969zM18.984 18.984v-1.969h-1.969v1.969h1.969zM12.984 6.984v-1.969h-1.969v1.969h1.969zM12.984 11.016v-2.016h-1.969v2.016h1.969zM12.984 15v-2.016h-1.969v2.016h1.969zM12.984 18.984v-1.969h-1.969v1.969h1.969zM6.984 11.016v-2.016h-1.969v2.016h1.969zM6.984 15v-2.016h-1.969v2.016h1.969zM6.984 18.984v-1.969h-1.969v1.969h1.969zM15 11.016h6v9.984h-18v-14.016h6v-1.969l3-3 3 3v6z"></path>
        </svg>
      )
    case 'tie':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 32 32">
          <path d="M16.902 8.968l1.248-5.924h-6.349l1.297 5.924h3.804zM13.071 10.077l-4.2 13.383 6.35 5.275 5.861-5.275-4.104-13.383h-3.907zM18.834 11.934l2.932 8.060h1.758v-1.808l-4.69-6.252z"></path>
        </svg>
      )
    case 'couch':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
          <path d="M18 5.016c1.078 0 2.016 0.891 2.016 1.969v2.156c-1.172 0.422-2.016 1.5-2.016 2.813v2.063h-12v-2.063c0-1.313-0.844-2.391-2.016-2.813v-2.156c0-1.078 0.938-1.969 2.016-1.969h12zM21 9.984c1.078 0 2.016 0.938 2.016 2.016v5.016c0 1.078-0.938 1.969-2.016 1.969h-18c-1.078 0-2.016-0.891-2.016-1.969v-5.016c0-1.078 0.938-2.016 2.016-2.016s2.016 0.938 2.016 2.016v3h13.969v-3c0-1.078 0.938-2.016 2.016-2.016z"></path>
        </svg>
      )
    case 'defect':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
          <path d="M21.171 15.398l-5.912-9.854c-0.776-1.293-1.963-2.033-3.259-2.033s-2.483 0.74-3.259 2.031l-5.912 9.856c-0.786 1.309-0.872 2.705-0.235 3.83 0.636 1.126 1.878 1.772 3.406 1.772h12c1.528 0 2.77-0.646 3.406-1.771 0.637-1.125 0.551-2.521-0.235-3.831zM12 17.549c-0.854 0-1.55-0.695-1.55-1.549s0.695-1.551 1.55-1.551 1.55 0.696 1.55 1.551c0 0.854-0.696 1.549-1.55 1.549zM13.633 10.125c-0.011 0.031-1.401 3.468-1.401 3.468-0.038 0.094-0.13 0.156-0.231 0.156s-0.193-0.062-0.231-0.156l-1.391-3.438c-0.090-0.233-0.129-0.443-0.129-0.655 0-0.965 0.785-1.75 1.75-1.75s1.75 0.785 1.75 1.75c0 0.212-0.039 0.422-0.117 0.625z"></path>
        </svg>
      )
    case 'recliner':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
          <path d="M16.219 15l5.766 4.5-1.5 1.5-3.797-3h-6.844c-1.453 0-2.672-1.031-2.953-2.438l-1.359-5.906c-0.188-1.219 0.609-2.391 1.828-2.625h0.047c0.712-0.102 1.327 0.096 1.734 0.422l1.641 1.266c1.266 0.984 3.047 1.594 4.688 1.266v2.156c-1.875 0.328-3.563-0.328-5.156-1.219l1.031 4.078h4.875zM15.984 18.984v2.016h-7.031c-2.484 0-4.594-1.781-4.969-4.219l-1.969-9.797h1.969l1.969 9.469c0.234 1.453 1.5 2.531 3 2.531h7.031zM5.344 5.625c-0.891-0.656-1.078-1.875-0.469-2.766s1.875-1.125 2.766-0.516c0.891 0.656 1.125 1.922 0.516 2.813-0.656 0.891-1.922 1.078-2.813 0.469z"></path>
        </svg>
      )
    case 'store':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
          <path d="M12 18v-3.984h-6v3.984h6zM21 14.016h-0.984v6h-2.016v-6h-3.984v6h-10.031v-6h-0.984v-2.016l0.984-5.016h16.031l0.984 5.016v2.016zM20.016 3.984v2.016h-16.031v-2.016h16.031z"></path>
        </svg>
      )
    case 'widgets':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
          <path d="M16.641 1.688l5.672 5.672-5.672 5.625h4.359v8.016h-8.016v-8.016h3.656l-5.625-5.625v3.656h-8.016v-8.016h8.016v4.359zM3 21v-8.016h8.016v8.016h-8.016z"></path>
        </svg>
      )
    case 'wrench':
      return (
        <svg className={props.className} width="24" height="24" viewBox="-2 0 32 32">
          <path d="M6 23c0-0.547-0.453-1-1-1s-1 0.453-1 1 0.453 1 1 1 1-0.453 1-1zM16.063 16.438l-10.656 10.656c-0.359 0.359-0.875 0.578-1.406 0.578s-1.047-0.219-1.422-0.578l-1.656-1.687c-0.375-0.359-0.594-0.875-0.594-1.406s0.219-1.047 0.594-1.422l10.641-10.641c0.812 2.047 2.453 3.687 4.5 4.5zM25.969 9.641c0 0.516-0.187 1.156-0.359 1.656-0.984 2.781-3.656 4.703-6.609 4.703-3.859 0-7-3.141-7-7s3.141-7 7-7c1.141 0 2.625 0.344 3.578 0.984 0.156 0.109 0.25 0.25 0.25 0.438 0 0.172-0.109 0.344-0.25 0.438l-4.578 2.641v3.5l3.016 1.672c0.516-0.297 4.141-2.578 4.453-2.578s0.5 0.234 0.5 0.547z"></path>
        </svg>
      )
    case 'radio':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
          <path d="M12 20.016c4.406 0 8.016-3.609 8.016-8.016s-3.609-8.016-8.016-8.016-8.016 3.609-8.016 8.016 3.609 8.016 8.016 8.016zM12 2.016c5.531 0 9.984 4.453 9.984 9.984s-4.453 9.984-9.984 9.984-9.984-4.453-9.984-9.984 4.453-9.984 9.984-9.984z"></path>
        </svg>
      )
    case 'radio-checked':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
          <path d="M12 20.016c4.406 0 8.016-3.609 8.016-8.016s-3.609-8.016-8.016-8.016-8.016 3.609-8.016 8.016 3.609 8.016 8.016 8.016zM12 2.016c5.531 0 9.984 4.453 9.984 9.984s-4.453 9.984-9.984 9.984-9.984-4.453-9.984-9.984 4.453-9.984 9.984-9.984zM12 6.984c2.766 0 5.016 2.25 5.016 5.016s-2.25 5.016-5.016 5.016-5.016-2.25-5.016-5.016 2.25-5.016 5.016-5.016z"></path>
        </svg>
      )
    case 'check':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
          <path d="M18.984 3c1.078 0 2.016 0.938 2.016 2.016v13.969c0 1.078-0.938 2.016-2.016 2.016h-13.969c-1.078 0-2.016-0.938-2.016-2.016v-13.969c0-1.078 0.938-2.016 2.016-2.016h13.969zM18.984 5.016h-13.969v13.969h13.969v-13.969z"></path>
        </svg>
      )
    case 'check-checked':
      return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
          <path d="M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563-1.406 1.406zM18.984 3c1.125 0 2.016 0.938 2.016 2.016v13.969c0 1.078-0.891 2.016-2.016 2.016h-13.969c-1.125 0-2.016-0.938-2.016-2.016v-13.969c0-1.078 0.891-2.016 2.016-2.016h13.969z"></path>
        </svg>
      )
    case 'back':
      return (
        <svg className={props.className} width="18" height="18" viewBox="0 0 18 18">
          <path d="M15 8.25H5.87l4.19-4.19L9 3 3 9l6 6 1.06-1.06-4.19-4.19H15v-1.5z" />
        </svg>
      )
    case 'face-straight':
      return (
        <img src="images/straight.png" width="24" height="24" />
      )
    case 'face-frown':
      return (
        <img src="images/annoyed.png" width="24" height="24" />
      )
    case 'face-disappointed':
      return (
        <img src="images/disappointed.png" width="24" height="24" />
      )
    case 'face-angry':
      return (
        <img src="images/angry.png" width="24" height="24" />
      )
    case 'close':
      return (
        <img src="/images/close.png" width="24" height="24" />
      )
    case 'camera':
      return (
        <svg className={props.className} viewBox="0 0 24 24">
          <path d="M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z" />
        </svg>
      )

    case 'checkmark':
      return (
        <svg className={props.className} viewBox="0 0 24 24">
          <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
        </svg>
      )
    case 'closesvg':
      return (
        <svg className={props.className} viewBox="0 0 24 24">
          <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
      )
    case 'refresh':
      return (
        <svg className={props.className} viewBox="0 0 24 24">
          <path d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
        </svg>
      )
    case 'help':
      return (
        <svg className={props.className} viewBox="0 0 24 24">
          <path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" />
        </svg>
      )
    default:
      return <div></div>
  }
}

export default createComponentWithProxy((props: IProps) => ({
  '> path': {
    fill: props.color,
  }
}), Icon)
