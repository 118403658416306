import gql from 'graphql-tag'
import { UserFragment } from './UserFragment.graphql'

export const LogoutMutation = gql`
  mutation LogoutMutation {
    logout {
      ...UserFragment
    }
  }
  ${UserFragment}
`
