import * as React from 'react'
import { createComponentWithProxy } from 'react-fela'

export default createComponentWithProxy((props) => ({
  fontSize: '12px',
  color: props.error
    ? props.theme.errorColor
    : 'rgba(0, 0, 0, .4)',
  paddingBottom: '4px',
}))
