import * as React from 'react'
import Icon from '../../Icon/Icon'
import Button from '../../Button/Button'

import './style.scss'

interface IProps {
  onCloseClick: Function,
  onButtonClick: Function,
  buttonCopy: string
}

interface IState {
}

class TipsOverlay extends React.Component<IProps, IState> {

    getScannerTips(buttonCopy, onButtonClick) {
        return (
            <div className="barcode-scanner-static__tips-scan">
                <div className="barcode-scanner-static__tips-images">
                    <div className="barcode-scanner-static__tips-images-tip1">
                        <img src="/images/scanner-tip1.jpg" alt="Tip"/>
                        <span className="skip-icon">
                            <Icon name="closesvg" color="#fff" />
                        </span>
                    </div>
                    <div className="barcode-scanner-static__tips-images-tip2">
                        <img src="/images/scanner-tip2.jpg" alt="Tip"/>
                        <span className="add-icon">
                            <Icon name="checkmark" color="#fff" />
                        </span>
                    </div>
                </div>
                <ul className="barcode-scanner-static__tips-list">
                    <li>Flatten the barcode.</li>
                    <li>Focus your shot by moving your phone closer to or further from the barcode.</li>
                    <li>Fit the scan-space box to the barcode.</li>
                    <li>Make sure your shot is well lit.</li>
                </ul>

                <Button handleClick={ onButtonClick } label={ buttonCopy }/>
            </div>
        )
    }

    render() {
        const { onCloseClick, onButtonClick, buttonCopy } = this.props

        return (
            <div className="barcode-scanner-static__tips-overlay">
                <span onClick={ onCloseClick } className="barcode-scanner-static__tips-overlay-close">
                    <i className="icon-close" />
                </span>
                <p className="barcode-scanner-static__tips-overlay-title">How To Scan A Barcode</p>
                { this.getScannerTips(buttonCopy, onButtonClick) }
            </div>
        )
    }
}

export default TipsOverlay
