import * as React from 'react'
import * as request from 'superagent'
import { createComponent, createComponentWithProxy } from 'react-fela'
import { Link, Route } from 'react-router-dom'
import AppBar from '../../components/AppBar/AppBar'
import Wrapper from '../../components/Grid/Wrapper'
import Button from '../../components/Button/Button'
import Heading from '../../components/Heading/Heading'
import Row from '../../components/Grid/Row'
import Col from '../../components/Grid/Col'

interface IProps {
  isAuthenticated: boolean
  logout: {}
  message: string
  className?: string
}

const Home = (props: IProps) => (
  <Wrapper className={props.className}>
    <AppBar
      isAuthenticated={props.isAuthenticated}
      logout={props.logout}
      {...this.props}>
      <img src="/images/we-icon.png" />
    </AppBar>
    <InfoBox>
      <Row>
        <Col>
          <Heading>{props.message
            ? 'Completed'
            : 'West Elm Product Quality Reporting Tool'}</Heading>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            {props.message
              ? props.message
              : 'This tool should be used to report product quality issues that arise during the course of your work.'}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to="/issue">
            <Button primary type="submit" label="New Issue" />
          </Link>
        </Col>
      </Row>
    </InfoBox>
  </Wrapper>
)

const InfoBox = createComponent(() => ({
  maxWidth: '80%',
  margin: 'auto',
  textAlign: 'center',
  paddingTop: '60px',

  tablet: {
    maxWidth: '60%',
  },

  desktop: {
    maxWidth: '40%',
  }
}))

export default createComponentWithProxy((props) => ({

}), Home)
