import * as React from 'react'
import { createComponent, createComponentWithProxy } from 'react-fela'
import Icon from '../Icon/Icon'

interface IProps {
  label: string
  icon?: string
  selected?: boolean
  disabled?: boolean
  className?: string
  onClick?: any
}

const RadioChoice = (props: IProps) => (
  <div
    className={props.className}
    onClick={props.onClick}>
    <span>
      {props.selected
        ? <ColoredIcon
          selected={props.selected}
          name={props.icon || 'radio-checked'} />
        : <ColoredIcon
          selected={props.selected}
          name={props.icon || 'radio'} />
      }
    </span>
    <div>{props.label}</div>
  </div>
)

const ColoredIcon = createComponentWithProxy((props) => ({
  '> path': {
    fill: 'rgba(0, 0, 0, .7)'
  },
  extend: {
    condition: props.selected,
    style: {
      '> path': {
        fill: props.theme.primaryColor
      }
    }
  }
}), Icon)

export default createComponentWithProxy((props) => ({
  display: 'block',
  lineHeight: '18px',
  cursor: 'pointer',
  padding: '10px 0',
  wordBreak: 'break-word',

  '> span': {
    float: 'left',
    marginRight: '5px',
    transform: 'translateY(-3px)',
  },

  extend: [{
    condition: props.disabled,
    style: {
      opacity: '0.4',
      cursor: 'default',
    }
  }, {
    condition: props.selected,
    style: {
      '> div': {
        color: props.theme.primaryColor
      }
    }
  }]
}), RadioChoice, ['selected', 'disabled'])
