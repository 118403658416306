import * as React from 'react'
import { createComponent } from 'react-fela'

const FullOverlay = (props) => (
  <div className={props.className}>
    {props.children}
  </div>
)

const FullOverlayWithStyle = createComponent((props) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 2,
  background: '#fff',
}))

export default FullOverlayWithStyle
