import * as React from 'react'
import { createComponent } from 'react-fela'

interface IProps {
  background?: string
  expanded?: boolean
}

export default createComponent((props: IProps) => ({
  marginBottom: '30px',
  paddingLeft: '30px',
  paddingTop: '20px',
  paddingRight: '30px',
  background: props.background
    ? props.background
    : 'transparent',

  extend: {
    condition: props.expanded,
    style: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  }
}))
