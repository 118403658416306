import * as React from 'react'
import { createComponentWithProxy } from 'react-fela'
import Icon from '../Icon/Icon'

interface IProps {
  value: string
  label: string
  name?: string
  subtitle?: string
  icon?: string
  disabled?: boolean
  className?: string
  count?: number
  selected?: boolean
  onClick?: any
}

const Choice = (props: IProps) => (
  <div onClick={props.onClick} className={props.className}>
    {props.icon &&
      <span><ColoredIcon selected={props.selected} name={props.icon} /></span>
    }
    <div>{props.label}</div>
    {props.subtitle && <strong>{props.subtitle}</strong>}
  </div>
)

const ColoredIcon = createComponentWithProxy((props) => ({
  '> path': {
    fill: 'rgba(0, 0, 0, .5)'
  },

  extend: {
    condition: props.selected,
    style: {
      '> path': {
        fill: props.theme.primaryColor
      }
    }
  }
}), Icon)

export default createComponentWithProxy((props) => ({
  position: 'relative',
  fontWeight: 'bold',
  fontSize: '12px',
  textTransform: 'uppercase',
  cursor: 'pointer',
  borderRadius: '5px',
  padding: '10px 10px 10px 42px',

  '> span': {
    position: 'absolute',
    left: '12px',
    top: '13px',
  },

  '> div': {
    verticalAlign: 'top',
    lineHeight: '28px',
  },

  '> strong': {
    fontWeight: 'normal',
    fontSize: '10px',
    textTransform: 'initial',
  },

  extend: [{
    condition: props.selected,
    style: {
      background: 'rgba(255, 255, 255, .6)',
      '> div': {
        color: props.theme.primaryColor
      }
    }
  }, {
    condition: props.subtitle,
    style: {
      '> div': {
        lineHeight: 'initial',
      }
    }
  }],

  tablet: {
    float: 'left',
    textAlign: 'center',
    lineHeight: 'initial',
    width: 100 / props.count + '%',
    padding: '10px',

    '> span': {
      position: 'static',
      display: 'block',
      float: 'none',
      marginRight: 0,
      marginBottom: '10px',
    },

    '> div': {
      height: '23px',
      lineHeight: 'initial',
    },
  },
}), Choice, ['subtitle', 'selected'])
