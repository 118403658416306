import * as React from 'react'
import { createComponent } from 'react-fela'

interface IProps {
  children: React.ReactElement<Element>
  mobile?: string
  tablet?: string
  className?: string
}

const Col = (props: IProps) => (
  <div className={props.className}>
    {props.children}
  </div>
)

export default createComponent((props) => {
  const mobile = props.mobile || props.theme.gridColumns
  const tablet = props.tablet || props.theme.gridColumns
  const mobileRatio = mobile / props.theme.gridColumns
  const tabletRatio = tablet / props.theme.gridColumns

  return {
    float: 'left',
    paddingBottom: '20px',
    paddingRight: props.theme.gridGutter,
    width: mobileRatio * 100 + '%',

    tablet: {
      paddingRight: props.theme.gridGutter,
      width: tabletRatio * 100 + '%',
    },

    extend: [{
      condition: mobileRatio === 1,
      style: {
        paddingRight: 0,
      }
    }, { // this does not work
      condition: tabletRatio === 1,
      style: {
        tablet: {
          paddingRight: 0,
        }
      }
    }]
  }
}, Col)
