import gql from 'graphql-tag'

export const NotifyTeamMutation = gql`
  mutation NotifyTeamMutation(
    $issue: IssueInput!
    $error: String!
  ) {
    notifyTeam(
      issue: $issue,
      error: $error
    )
  }
`
