import * as React from 'react';
import { throttle } from 'lodash';
import classnames from 'classnames';

import './style.scss';

interface IProps {
  src: string,
  onAreaChange: Function,
  minWidth?: number,
  minHeight?: number,
  maxWidth?: string,
  maxHeight?: string,
}

interface IState {
  cropping: boolean,
  resizing: boolean,
}

class ImageCropper extends React.Component<IProps, IState> {
  private cardinalPoints
  private dragging
  private resizing
  private cropArea

  static defaultProps = {
    minWidth: 30,
    minHeight: 30,
    maxWidth: '100%',
    maxHeight: '100%',
  }

  constructor(props) {
    super(props);

    this.state = {
      cropping: false,
      resizing: false,
    }
    this.cardinalPoints = ['ne', 'n', 'nw', 'w', 'sw', 's', 'se', 'e']

    // Internal (not render specific) state
    this.dragging = null;
    this.resizing = null;
    this.cropArea = {
      width: 200,
      height: 200,
      x: 10,
      y: 10,
    };

    this.handlePointerMove = this.handlePointerMove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onResize = this.onResize.bind(this);

    this.onAreaChange = throttle(this.onAreaChange.bind(this), 300, { trailing: true });
  }

  onAreaChange() {
    const ratio = this.refs.image.naturalWidth / this.refs.image.offsetWidth;

    this.props.onAreaChange({
      x: this.cropArea.x * ratio,
      y: this.cropArea.y * ratio,
      width: this.cropArea.width * ratio,
      height: this.cropArea.height * ratio,
    });
  }

  handlePointerMove(event) {
    if (this.dragging && !this.resizing) {
      this.dragCropArea(event);
    } else if (this.resizing) {
      this.resizeCropArea(event);
    }
  }

  handleCancel(event) {
    if (this.dragging || this.resizing) {
      event.preventDefault();
    }

    if (this.dragging) {
      this.dragging = null;
    }

    if (this.resizing) {
      this.resizing = null;
    }
  }

  startDragCropArea(event) {
    const pointer = this.getPointerPosition(event);

    this.dragging = {
      pointerX: pointer.x,
      pointerY: pointer.y,
      x: this.cropArea.x,
      y: this.cropArea.y,
    };

    event.preventDefault();
  }

  dragCropArea(event) {
    const pointer = this.getPointerPosition(event);

    const deltaX = pointer.x - this.dragging.pointerX;
    const deltaY = pointer.y - this.dragging.pointerY;

    this.cropArea.x = this.dragging.x + deltaX;
    this.cropArea.y = this.dragging.y + deltaY;

    this.clipCropArea(true, false);
    this.renderCropArea();
    this.onAreaChange();

    event.preventDefault();
  }

  clipCropArea(clipPosition = true, clipSize = true) {
    const { minWidth, minHeight } = this.props;
    const imgWidth = this.refs.image.offsetWidth;
    const imgHeight = this.refs.image.offsetHeight;

    if (clipSize && this.cropArea.width > imgWidth - this.cropArea.x) {
      this.cropArea.width = imgWidth - this.cropArea.x;
    }

    if (clipSize && this.cropArea.height > imgHeight - this.cropArea.y) {
      this.cropArea.height = imgHeight - this.cropArea.y;
    }

    if (clipSize && this.cropArea.width < minWidth) {
      this.cropArea.width = minWidth;
    }

    if (clipSize && this.cropArea.height < minHeight) {
      this.cropArea.height = minHeight;
    }

    if (clipPosition && this.cropArea.x > imgWidth - this.cropArea.width) {
      this.cropArea.x = imgWidth - this.cropArea.width;
    }

    if (clipPosition && this.cropArea.y > imgHeight - this.cropArea.height) {
      this.cropArea.y = imgHeight - this.cropArea.height;
    }

    if (clipPosition && this.cropArea.x < 0) {
      this.cropArea.x = 0;
    }

    if (clipPosition && this.cropArea.y < 0) {
      this.cropArea.y = 0;
    }
  }

  renderCropArea() {
    this.refs.cropArea.style.left = `${this.cropArea.x}px`;
    this.refs.cropArea.style.top = `${this.cropArea.y}px`;
    this.refs.cropArea.style.width = `${this.cropArea.width}px`;
    this.refs.cropArea.style.height = `${this.cropArea.height}px`;
  }

  getPointerPosition(event) {
    if (event.touches) {
      return {
        x: event.touches[0].pageX,
        y: event.touches[0].pageY,
      };
    }

    return {
      x: event.pageX,
      y: event.pageY,
    };
  }

  startResizeCropArea(event, dir) {
    const pointer = this.getPointerPosition(event);

    this.resizing = {
      pointerX: pointer.x,
      pointerY: pointer.y,
      x: this.cropArea.x,
      y: this.cropArea.y,
      width: this.cropArea.width,
      height: this.cropArea.height,
      dir,
    };

    event.preventDefault();
  }

  resizeCropArea(event) {
    const pointer = this.getPointerPosition(event);
    let deltaX = pointer.x - this.resizing.pointerX;
    let deltaY = pointer.y - this.resizing.pointerY;
    const dir = this.resizing.dir;

    if (dir === 'w' || dir === 'nw' || dir === 'sw') {
      if (-deltaX > this.resizing.x) {
        deltaX = -this.resizing.x;
      }

      if (deltaX > this.resizing.width - this.props.minWidth) {
        deltaX = this.resizing.width - this.props.minWidth;
      }
    }

    if (dir === 'n' || dir === 'ne' || dir === 'nw') {
      if (-deltaY > this.resizing.y) {
        deltaY = -this.resizing.y;
      }

      if (deltaY > this.resizing.height - this.props.minHeight) {
        deltaY = this.resizing.height - this.props.minHeight;
      }
    }

    if (dir === 'nw') {
      this.cropArea.x = this.resizing.x - (-deltaX);
      this.cropArea.y = this.resizing.y - (-deltaY);
      this.cropArea.width = this.resizing.width + (-deltaX);
      this.cropArea.height = this.resizing.height + (-deltaY);
    } else if (dir === 'n') {
      this.cropArea.y = this.resizing.y - (-deltaY);
      this.cropArea.height = this.resizing.height + (-deltaY);
    } else if (dir === 'ne') {
      this.cropArea.y = this.resizing.y - (-deltaY);
      this.cropArea.width = this.resizing.width + deltaX;
      this.cropArea.height = this.resizing.height + (-deltaY);
    } else if (dir === 'e') {
      this.cropArea.width = this.resizing.width + deltaX;
    } else if (dir === 'se') {
      this.cropArea.width = this.resizing.width + deltaX;
      this.cropArea.height = this.resizing.height + deltaY;
    } else if (dir === 's') {
      this.cropArea.height = this.resizing.height + deltaY;
    } else if (dir === 'sw') {
      this.cropArea.x = this.resizing.x - (-deltaX);
      this.cropArea.width = this.resizing.width + (-deltaX);
      this.cropArea.height = this.resizing.height + deltaY;
    } else if (dir === 'w') {
      this.cropArea.x = this.resizing.x - (-deltaX);
      this.cropArea.width = this.resizing.width + (-deltaX);
    }

    this.clipCropArea();
    this.renderCropArea();
    this.onAreaChange();

    event.preventDefault();
  }

  onResize() {
    this.clipCropArea();
    this.renderCropArea();
    this.onAreaChange();
  }

  componentDidMount() {
    this.refs.cropArea.addEventListener('touchstart', this.startDragCropArea.bind(this), { passive: false });
    this.refs.cropArea.addEventListener('mousedown', this.startDragCropArea.bind(this), { passive: false });

    document.addEventListener('touchmove', this.handlePointerMove, { passive: false });
    document.addEventListener('mousemove', this.handlePointerMove, { passive: false });

    document.addEventListener('touchend', this.handleCancel);
    document.addEventListener('mouseup', this.handleCancel);

    window.addEventListener('resize', this.onResize);
    window.addEventListener('orientationchange', this.onResize);

    this.cardinalPoints.forEach(dir => {
      const handler = this.refs[`resizer_${dir}`];
      handler.addEventListener('touchstart', e => this.startResizeCropArea(e, dir), { passive: false });
      handler.addEventListener('mousedown', e => this.startResizeCropArea(e, dir), { passive: false });
    });

    this.refs.image.addEventListener('load', () => {
      this.cropArea.width = this.refs.image.offsetWidth / 2;
      this.cropArea.height = this.refs.image.offsetWidth / 2;
      this.cropArea.x = (this.refs.image.offsetWidth - this.cropArea.width) / 2;
      this.cropArea.y = (this.refs.image.offsetHeight - this.cropArea.height) / 2;
      this.renderCropArea();
      this.onAreaChange();
      this.setState({ cropping: true });
    });
  }

  componentWillUnmount() {
    document.removeEventListener('touchmove', this.handlePointerMove);
    document.removeEventListener('mousemove', this.handlePointerMove);
    document.removeEventListener('touchend', this.handleCancel);
    document.removeEventListener('mouseup', this.handleCancel);

    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('orientationchange', this.onResize);
  }

  render() {
    const { src, maxWidth, maxHeight } = this.props;
    const { cropping } = this.state;

    const cropAreaClass = classnames('image-cropper__crop-area', {
      'image-cropper__crop-area--hidden': !cropping
    });

    const imgStyle = {
      maxWidth,
      maxHeight,
    };

    return (
      <div className="image-cropper">
        <img src={src} className="image-cropper__image" ref="image" style={imgStyle} />

        <div className={cropAreaClass} ref="cropArea">
          <div className="image-cropper__crop-area-border" />
          {this.cardinalPoints.map(x => (
            <div key={x} className={`image-cropper__resizer image-cropper__resizer--${x}`}
              ref={`resizer_${x}`} />
          ))}
        </div>
      </div>
    );
  }
}

export default ImageCropper;
