import * as React from 'react'
import { createComponent } from 'react-fela'

export default createComponent((props) => ({
  overflow: 'hidden',

  // '> div': {
  //   paddingRight: props.theme.gridGutter
  // },

  // '> div:last-child': {
  //   paddingRight: 0,
  // }
}))
